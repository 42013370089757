import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards
import CardReseller from "./card-reseller";
import AddReseller from "./add-reseller";

import { getResellers as onGetResellers } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const ResellersPage = props => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const { resellers, isLoading } = useSelector(state => ({
    resellers: state.Resellers.resellers,
    isLoading: state.Resellers.isLoading,
  }));

  const [page, setPage] = useState(1);
  const [totalPage] = useState(5);

  const handleClick = () => {
    setShow(true);
  };

  const toggle = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  useEffect(() => {
    dispatch(onGetResellers());
  }, [dispatch]);

  const handlePageClick = page => {
    setPage(page);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Resellers | GAZAL</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Resellers" breadcrumbItem="All Resellers" />
          {/* TODO- add filteration/search */}
          <Row>
            <Col className="col-12 d-flex align-items-center justify-content-end mb-4">
              <button
                type="button"
                className="btn btn-primary w-xs"
                onClick={handleClick}
              >
                Add New Reseller
              </button>
            </Col>
          </Row>
          <Row>
            {/* Import Cards */}
            {isLoading ? (
              <p>Loading....</p>
            ) : resellers.length ? (
              <CardReseller resellers={resellers} />
            ) : (
              // TODO - replace with empty state
              <p>0 resellers</p>
            )}
          </Row>

          <Row>
            <Col lg="12">
              <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(totalPage), (item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={() => handlePageClick(i + 1)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
          <Modal isOpen={show} centered={true} size="lg" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {"Add New Reseller"}
            </ModalHeader>
            <ModalBody className="py-3 px-5">
              <AddReseller close={toggle} />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResellersPage);

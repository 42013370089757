import {
  ADD_CREDITS,
  ADD_CREDITS_FAIL,
  ADD_CREDITS_SUCCESS,
  CANCEL_TRANSACTION,
  CANCEL_TRANSACTION_FAIL,
  CANCEL_TRANSACTION_SUCCESS,
  GET_TRANSACTION,
  GET_TRANSACTIONS_LIST,
  GET_TRANSACTIONS_LIST_FAIL,
  GET_TRANSACTIONS_LIST_SUCCESS,
  GET_TRANSACTION_FAIL,
  GET_TRANSACTION_SUCCESS,
  RESET_ERROR,
  RESET_TRANSACTION,
  SEND_OTP,
  SEND_OTP_FAIL,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";

export const getTransactions = () => ({
  type: GET_TRANSACTIONS_LIST,
});

export const getTransactionsSuccess = payload => ({
  type: GET_TRANSACTIONS_LIST_SUCCESS,
  payload: payload,
});

export const getTransactionsFail = error => ({
  type: GET_TRANSACTIONS_LIST_FAIL,
  payload: error.response.data.messages,
});

export const getTransactionById = transactionId => ({
  type: GET_TRANSACTION,
  payload: transactionId,
});

export const getTransactionsByIdSuccess = transaction => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: transaction,
});

export const getTransactionsByIdFail = error => ({
  type: GET_TRANSACTION_FAIL,
  payload: error.response.data.messages,
});

export const sendOtp = data => ({
  type: SEND_OTP,
  payload: data,
});

export const sendOtpSuccess = transactionDetails => ({
  type: SEND_OTP_SUCCESS,
  payload: transactionDetails,
});

export const sendOtpFail = error => ({
  type: SEND_OTP_FAIL,
  payload: error.response.data.messages,
});

export const verifyOtp = data => ({
  type: VERIFY_OTP,
  payload: data,
});

export const verifyOtpSuccess = data => ({
  type: VERIFY_OTP_SUCCESS,
  payload: data,
});

export const verifyOtpFail = error => ({
  type: VERIFY_OTP_FAIL,
  payload: error.response.data.messages,
});

export const addCredits = body => ({
  type: ADD_CREDITS,
  payload: body,
});

export const addCreditsSuccess = data => ({
  type: ADD_CREDITS_SUCCESS,
  payload: data,
});

export const addCreditsFail = error => ({
  type: ADD_CREDITS_FAIL,
  payload: error.response.data.messages,
});

export const cancelTransaction = transactionId => ({
  type: CANCEL_TRANSACTION,
  payload: transactionId,
});
export const cancelTransactionSuccess = data => ({
  type: CANCEL_TRANSACTION_SUCCESS,
  payload: data,
});

export const cancelTransactionFail = error => ({
  type: CANCEL_TRANSACTION_FAIL,
  payload: error.response.data.messages,
});

export const resetTransaction = () => ({
  type: RESET_TRANSACTION,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

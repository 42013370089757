import { getActivityLogs, getActivityLogsById } from "helpers/backend_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getActivityLogsByIdFail,
  getActivityLogsByIdSuccess,
  getActivityLogsFail,
  getActivityLogsSuccess,
} from "./actions";
import { GET_ACTIVITY_LOGS, GET_ACTIVITY_LOG_BY_ID } from "./actionTypes";

function* fetchActivityLogs() {
  try {
    const response = yield call(getActivityLogs);
    yield put(getActivityLogsSuccess(response.data));
  } catch (error) {
    yield put(getActivityLogsFail(error));
  }
}

function* fetchActivityLogsById() {
  try {
    const response = yield call(getActivityLogsById);
    yield put(getActivityLogsByIdSuccess(response));
  } catch (error) {
    yield put(getActivityLogsByIdFail(error));
  }
}

function* activityLogsSaga() {
  yield takeEvery(GET_ACTIVITY_LOGS, fetchActivityLogs);
  yield takeEvery(GET_ACTIVITY_LOG_BY_ID, fetchActivityLogsById);
}

export default activityLogsSaga;

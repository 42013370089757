import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { isEmpty } from "lodash";

import DepositDetails from "./details";
import DocumentsList from "./documentsList";

import Breadcrumbs from "components/Common/Breadcrumb";
import { getDepositDetails as onGetDepositDetails } from "store/actions";

const DepositOverview = ({ t }) => {
  const dispatch = useDispatch();
  const { depositId } = useParams();

  const { depositDetails, isLoading } = useSelector(state => ({
    depositDetails: state.Deposits.depositDetails,
    isLoading: state.Deposits.isLoading,
  }));

  useEffect(() => {
    dispatch(onGetDepositDetails(depositId));
  }, [depositId, dispatch, onGetDepositDetails]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("Deposit Details")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={t("Deposit")} breadcrumbItem={t("Details")} />
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            !isEmpty(depositDetails) && (
              <React.Fragment>
                <DepositDetails deposit={depositDetails} />
                <DocumentsList deposit={depositDetails} />
              </React.Fragment>
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

DepositOverview.propTypes = {
  t: PropTypes.any,
};

export default withRouter(
  connect(null, {})(withTranslation()(DepositOverview))
);

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/gazal/logo-primary.png";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Alert,
  Spinner,
} from "reactstrap";

import { userChangePassword } from "../../store/actions";
// TODO add hide password
import { verifyInviteToken } from "../../store/auth/login/actions";
const Changepw = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { email, isLoading } = useSelector(state => ({
    email: state.Profile.profile.email,
    isLoading: state.ChangePassword.isLoading,
  }));
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(t("required-password")),
      password: Yup.string().required(t("required-password")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], t("match-password"))
        .required(t("required-confirm-password")),
    }),
    onSubmit: values => {
      const data = {
        email,
        currentPassword: values.currentPassword,
        newPassword: values.password,
      };
      dispatch(userChangePassword(data));
      validation.resetForm();
    },
  });
  // TODO clear state
  const { changeError, changeSuccessMsg } = useSelector(state => ({
    changeError: state.ChangePassword.changeError,
    changeSuccessMsg: state.ChangePassword.changeSuccessMsg,
  }));
  useEffect(() => {
    const token = window.location.search.split("=")[1];
    if (token) {
      // TODO - retrieve from state
      const uid = localStorage.getItem("uid");
      dispatch(verifyInviteToken(uid, token));
    }
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <MetaTags>
          <title>
            {t("settings")} | {t("gazal")}
          </title>
        </MetaTags>
        <Container>
          {/* <ReAuthModal /> */}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t("change-password")}</h5>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    {changeError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(changeError)}
                      </Alert>
                    ) : null}
                    {changeSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t(changeSuccessMsg)}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("current-password")}
                        </Label>
                        <Input
                          name="currentPassword"
                          className="form-control"
                          placeholder={t("enter-current-password")}
                          type={"password"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.currentPassword || ""}
                          invalid={
                            validation.touched.currentPassword &&
                            validation.errors.currentPassword
                              ? true
                              : false
                          }
                        />
                        {/* <i
                          className="mdi mdi-eye"
                          onClick={togglePasswordVisiblity}
                        /> */}
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          {t("new-password")}
                        </Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder={t("enter-password")}
                          type={passwordShown ? "text" : "password"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {/* <i
                          className="mdi mdi-eye"
                          onClick={togglePasswordVisiblity}
                        /> */}
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("confirm-new-password")}
                        </Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder={t("confirm-password")}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-end">
                        <button
                          className="btn btn-primary w-md "
                          type="submit"
                          disabled={!validation.isValid || isLoading}
                        >
                          {isLoading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            t("change")
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Changepw;

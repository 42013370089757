import { all, fork } from "redux-saga/effects";

//public
// ? deposit
import depositsSaga from "./deposits/saga";
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import dashboardSaga from "./dashboard/saga";
import resellerUsersSaga from "./users/resellers/saga";
import transactionsSaga from "./transactions/saga";
import changeSaga from "./auth/changepwd/saga";
import resellersSaga from "./resellers/saga";
import activityLogsSaga from "./logs/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(changeSaga),
    fork(depositsSaga),
    fork(resellerUsersSaga),
    fork(transactionsSaga),
    fork(resellersSaga),
    fork(activityLogsSaga),
  ]);
}

import {
  DEPOSITS_FAILURE,
  GET_DEPOSIT_DETAILS,
  GET_DEPOSITS_SUCCESS,
  GET_DEPOSIT_DETAILS_SUCCESS,
  CREATE_NEW_DEPOSIT_SUCCESS,
  DELETE_DEPOSIT_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  deposits: [],
  depositDetails: {},
  lastItem: "",
  pageSize: 20,
  hasMore: false,
  error: {},
  isLoading: false,
};

const deposits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEPOSITS_FAILURE:
      return {
        ...state,
        error: action.payload?.response?.data,
      };
    case GET_DEPOSIT_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEPOSITS_SUCCESS:
      return {
        ...state,
        deposits: [...state.deposits, ...action.payload?.deposits],
        lastItem: action.payload?.lastItem,
        hasMore: action.payload?.hasMore,
      };

    case GET_DEPOSIT_DETAILS_SUCCESS:
      return {
        ...state,
        depositDetails: action.payload,
        isLoading: false,
      };

    case CREATE_NEW_DEPOSIT_SUCCESS:
      return {
        ...state,
        deposits: [...state.deposits, action.payload?.data?.data],
      };

    case DELETE_DEPOSIT_SUCCESS:
      return {
        ...state,
        deposits: state.deposits.filter(
          deposit => deposit.depositId !== action.payload?.data
        ),
      };

    default:
      return state;
  }
};

export default deposits;

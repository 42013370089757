import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Row, Col, Card, CardBody, Spinner } from "reactstrap";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

const WelcomeComp = ({ profile }) => {
  const { t } = useTranslation();
  return (
    // <>
    //   <Card className="bg-primary bg-soft">
    //     <div>
    //       <Row>
    //         <Col xs="7">
    //           <div className="text-primary p-3">
    //             <h5 className="text-primary">{t("welcome-back")}</h5>
    //             {/* <p>{`${type} ${role}`}</p> */}
    //             <ul className="ps-3 mb-0">
    //               <li className="py-1">{`${name}`}</li>
    //               {/* <h5 className="font-size-15 text-truncate">{`${name}`}</h5> */}
    //               <li className="py-1">{position}</li>
    //             </ul>
    //             {/* <p className="text-muted mb-0 text-truncate">{position}</p> */}
    //           </div>
    //         </Col>
    //         <Col xs="5" className="align-self-end mt-4">
    //           <img src={profileImg} alt="" className="img-fluid" height="90" />
    //         </Col>
    //       </Row>
    //     </div>
    //   </Card>
    // </>
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="9">
              <div className="text-primary p-3">
                <h5 className="text-primary">{t("welcome-back")}</h5>
                {/* {profile && <p>{`${profile.type} ${profile.role}`}</p>} */}
              </div>
            </Col>
            <Col xs="3" className="align-self-end mt-4">
              {profile && (
                <img
                  src={profileImg}
                  alt=""
                  className="img-fluid"
                  width="100"
                />
              )}
            </Col>
          </Row>
        </div>
        {profile ? (
          <CardBody className="pt-0">
            <Row>
              <Col sm="6">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">{`${profile.firstName} ${profile.lastName}`}</h5>
                <p className="text-muted mb-0 text-truncate">
                  {profile.position || "---"}
                </p>
              </Col>

              <Col sm="6">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      {/* TODO- remove hardcoded value */}
                      <h5 className="font-size-15">{t("reseller")}</h5>
                      <p className="text-muted mb-0">{profile.name}</p>
                    </Col>
                    {/* <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col> */}
                  </Row>
                  {/* <div className="mt-4">
                  <Link to="" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        ) : (
          <Col className="bg-primary bg-soft d-flex align-items-center justify-content-center ">
            <Spinner size="md" className="mt-2 mb-5" />
          </Col>
        )}
      </Card>
    </React.Fragment>
  );
};

WelcomeComp.propTypes = {
  profile: PropTypes.object,
  // firstName: PropTypes.string,
  // lastName: PropTypes.string,
};

export default WelcomeComp;

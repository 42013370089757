import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    let decoded = "";
    if (accessToken) {
      decoded = jwt_decode(accessToken);
      localStorage.setItem("role", decoded.role);
      localStorage.setItem("type", decoded.type);
    }
    // if (!accessToken) {
    //   // TODO - Refactor - this will create a bug when logging in as gazal
    //   window.location.replace(`/reseller/login`);
    // }
  }, []);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !accessToken) {
          return (
            <Redirect
              to={{
                pathname: "/reseller/login",
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;

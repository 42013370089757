/* GET_TRANSACTIONS_LIST */
export const GET_TRANSACTIONS_LIST = "GET_TRANSACTIONS_LIST";
export const GET_TRANSACTIONS_LIST_SUCCESS = "GET_TRANSACTIONS_LIST_SUCCESS";
export const GET_TRANSACTIONS_LIST_FAIL = "GET_TRANSACTIONS_LIST_FAIL";

/* GET_TRANSACTION */
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";

/* ADD_CREDITS */
export const ADD_CREDITS = "ADD_CREDITS";
export const ADD_CREDITS_SUCCESS = "ADD_CREDITS_SUCCESS";
export const ADD_CREDITS_FAIL = "ADD_CREDITS_FAIL";

/* SEND_OTP */
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

/* VERIFY_OTP */
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

/* CANCEL_TRANSACTION */
export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
export const CANCEL_TRANSACTION_SUCCESS = "CANCEL_TRANSACTION_SUCCESS";
export const CANCEL_TRANSACTION_FAIL = "CANCEL_TRANSACTION_FAIL";

export const RESET_TRANSACTION = "RESET_TRANSACTION";
export const RESET_ERROR = "RESET_ERROR";

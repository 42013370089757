import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";

import { Row, Col, Alert, Container, Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import { socialLogin } from "../../store/actions";

// import images
import logo from "assets/images/gazal/logo.png";

import CarouselPage from "pages/AuthenticationInner/CarouselPage";

const Login = props => {
  const dispatch = useDispatch();

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const signInWithGoogle = () => {
    dispatch(socialLogin(props.history));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login - GAZAL Admin</title>
      </MetaTags>

      <div className="account-pages">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={9}>
              <CarouselPage />
            </Col>
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div className="mb-4 mb-md-5">
                        <Link to="/gazal/login" className="d-block auth-logo">
                          <img
                            // style={{ float: "right" }}
                            src={logo}
                            alt=""
                            height="90"
                            className="auth-logo-dark"
                          />
                        </Link>
                      </div>
                      <div>
                        <h5 className="text-primary">Welcome,</h5>
                        <p className="text-muted">
                          Continue with your Gazal email.
                        </p>
                      </div>

                      <div className="mt-4">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mt-4 text-center">
                          <div className="mt-3 d-grid">
                            <Button
                              className="btn btn-success btn-block"
                              onClick={signInWithGoogle}
                            >
                              <i className="mdi mdi-google text-light" />
                            </Button>
                          </div>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                        {/* </Form> */}
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} GAZAL.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

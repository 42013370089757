import {
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_FAIL,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ACTIVITY_LOG_BY_ID_FAIL,
  GET_ACTIVITY_LOG_BY_ID_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  logs: [],
  error: "",
  successMsg: "",
};

const ActivityLogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case GET_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.payload,
      };
    case GET_ACTIVITY_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ACTIVITY_LOG_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_ACTIVITY_LOG_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ActivityLogs;

import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_RESELLER_USERS,
  GET_RESELLER_USER_DETAIL,
  ADD_NEW_RESELLER_USER,
  UPDATE_RESELLER_USER,
  UPDATE_RESELLER_USER_STATE,
  DISABLE_RESELLER_USER,
} from "./actionTypes";

import {
  getResellerUsersSuccess,
  getResellerUsersFail,
  getResellerUserDetailSuccess,
  getResellerUserDetailFail,
  addResellerUserFail,
  addResellerUserSuccess,
  updateResellerUserSuccess,
  updateResellerUserFail,
  disableResellerUserSuccess,
  disableResellerUserFail,
  updateResellerUserStateSuccess,
  updateResellerUserStateFail,
} from "./actions";

import {
  getResellerUsers,
  getResellerUserDetails,
  addNewUser,
  updateResellerUser,
  disableResellerUser,
  updateResellerUserState,
} from "helpers/backend_helper";

function* fetchResellerUsers({ payload: resellerId }) {
  try {
    const response = yield call(getResellerUsers, resellerId);
    yield put(getResellerUsersSuccess(response.data));
  } catch (error) {
    yield put(getResellerUsersFail(error.response.data.messages));
  }
}

function* fetchResellerUserDetail({ resellerUserId }) {
  try {
    const response = yield call(getResellerUserDetails, resellerUserId);
    yield put(getResellerUserDetailSuccess(response));
  } catch (error) {
    yield put(getResellerUserDetailFail(error));
  }
}

function* onaddNewUser({ payload: resellerUser }) {
  try {
    yield call(addNewUser, resellerUser);
    const createdUser = Object.assign(resellerUser, { state: "pending" });
    yield put(addResellerUserSuccess(createdUser));
  } catch (error) {
    if (error?.response?.data?.messages) {
      yield put(addResellerUserFail(error?.response?.data?.messages));
    } else {
      yield put(addResellerUserFail("general"));
    }
  }
}

function* onUpdateResellerUser({ payload: resellerUser }) {
  try {
    const response = yield call(updateResellerUser, resellerUser);
    yield put(updateResellerUserSuccess());
  } catch (error) {
    yield put(updateResellerUserFail(error));
  }
}

function* onUpdateResellerUserState({ payload: data }) {
  try {
    const response = yield call(updateResellerUserState, data.uid, data.state);
    yield put(updateResellerUserStateSuccess(response));
  } catch (error) {
    yield put(updateResellerUserStateFail(error));
  }
}

function* onDisableResellerUser({ resellerUserId, data }) {
  try {
    const response = yield call(disableResellerUser, resellerUserId, data);
    yield put(disableResellerUserSuccess(response));
  } catch (error) {
    yield put(disableResellerUserFail(error));
  }
}

function* resellerUsersSaga() {
  yield takeEvery(GET_RESELLER_USERS, fetchResellerUsers);
  yield takeEvery(GET_RESELLER_USER_DETAIL, fetchResellerUserDetail);
  yield takeEvery(ADD_NEW_RESELLER_USER, onaddNewUser);
  yield takeEvery(UPDATE_RESELLER_USER, onUpdateResellerUser);
  yield takeEvery(UPDATE_RESELLER_USER_STATE, onUpdateResellerUserState);
  yield takeEvery(DISABLE_RESELLER_USER, onDisableResellerUser);
}

export default resellerUsersSaga;

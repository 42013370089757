/* RESELLER USERS */
export const GET_RESELLER_USERS = "GET_RESELLER_USERS";
export const GET_RESELLER_USERS_SUCCESS = "GET_RESELLER_USERS_SUCCESS";
export const GET_RESELLER_USERS_FAIL = "GET_RESELLER_USERS_FAIL";

/* RESELLER USERS DETAIL*/
export const GET_RESELLER_USER_DETAIL = "GET_RESELLER_USER_DETAIL";
export const GET_RESELLER_USER_DETAIL_SUCCESS =
  "GET_RESELLER_USER_DETAIL_SUCCESS";
export const GET_RESELLER_USER_DETAIL_FAIL = "GET_RESELLER_USER_DETAIL_FAIL";

/**
 * add reseller user
 */
export const ADD_NEW_RESELLER_USER = "ADD_NEW_RESELLER_USER";
export const ADD_RESELLER_USER_SUCCESS = "ADD_RESELLER_USER_SUCCESS";
export const ADD_RESELLER_USER_FAIL = "ADD_RESELLER_USER_FAIL";

/**
 * Edit reseller user
 */
export const UPDATE_RESELLER_USER = "UPDATE_RESELLER_USER";
export const UPDATE_RESELLER_USER_SUCCESS = "UPDATE_RESELLER_USER_SUCCESS";
export const UPDATE_RESELLER_USER_FAIL = "UPDATE_RESELLER_USER_FAIL";

/**
 * Edit reseller user
 */
export const UPDATE_RESELLER_USER_STATE = "UPDATE_RESELLER_USER_STATE";
export const UPDATE_RESELLER_USER_STATE_SUCCESS =
  "UPDATE_RESELLER_USER_STATE_SUCCESS";
export const UPDATE_RESELLER_USER_STATE_FAIL =
  "UPDATE_RESELLER_USER_STATE_FAIL";

/**
 * Disable reseller user
 */
export const DISABLE_RESELLER_USER = "DISABLE_RESELLER_USER";
export const DISABLE_RESELLER_USER_SUCCESS = "DISABLE_RESELLER_USER_SUCCESS";
export const DISABLE_RESELLER_USER_FAIL = "DISABLE_RESELLER_USER_FAIL";

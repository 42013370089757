import { API_SUCCESS, API_FAIL, GET_DASHBOARD_DATA } from "./actionTypes";

const INIT_STATE = {
  dashboardData: {},
  isLoading: true,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DASHBOARD_DATA:
          return {
            ...state,
            dashboardData: action.payload.data.data,
            isLoading: false,
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_DASHBOARD_DATA:
          return {
            ...state,
            error: action.payload.error,
            isLoading: false,
          };

        default:
          return state;
      }
    default:
      return state;
  }
};

export default Dashboard;

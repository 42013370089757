import Datatable from "components/CommonForBoth/Datatable";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Container } from "reactstrap";
import { getActivityLogs as fetchLogs } from "store/actions";
import toastr from "toastr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { formatDateTime as handleValidDate, httpStatusColors as getBadgeColor } from "utils";
import { useTranslation } from "react-i18next";

const ActivityLogsList = () => {
  const { t } = useTranslation();
  toastr.options.positionClass = "toast-bottom-right";
  const dispatch = useDispatch();
  const { logs } = useSelector(state => ({
    logs: state.ActivityLogs.logs,
  }));
  const [values, setValues] = useState([])

  const userType = localStorage.getItem("type");

  const sorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
    {
      dataField: "requestMethod",
      order: "desc",
    },
    {
      dataField: "httpStatus",
      order: "desc",
    },
  ];

  const logsColumns = [
    {
      text: "logId",
      dataField: "logId",
      sort: false,
      hidden: true,
      formatter: item => <span className="hidden">{item.logId}</span>,
    },
    {
      dataField: "user",
      text: "User",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "ip",
      text: "IP Address",
      sort: true,
    },
    {
      dataField: "endTime",
      text: "Timestamp",
      sort: true,
      formatter: (cellContent, item) => <>{handleValidDate(item.endTime)}</>,
    },
    {
      dataField: "controller",
      text: "Controller",
      sort: true,
    },
    {
      dataField: "version",
      text: "Version",
      sort: true,
    },
    {
      dataField: "httpStatus",
      text: "Status",
      sort: true,
      
      formatter: (cellContent, item) => (
        <>
          <Badge
            className={"font-size-12 badge-soft-" + getBadgeColor(item.httpStatus)}
            color={getBadgeColor(item.httpStatus)}
            pill
          >
            {t(`${item.httpStatus}`)}
          </Badge>
        </>
      )
    },
    {
      dataField: "method",
      text: "Method",
      sort: true,
    }
  ];

  userType === "reseller" ? logsColumns.splice(1, 1) : false;

  useEffect(() => {
    dispatch(fetchLogs());
  }, [dispatch]);

  useEffect(() => {
    const values = logs ?? [];
    setValues(values);
  }, [logs]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Activity Logs | GAZAL</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Activity" breadcrumbItem="Logs" />
          <div>
            <Datatable columns={logsColumns} data={values} sorted={sorted} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ActivityLogsList;

import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import * as moment from "moment";
import { getColor } from "utils/getConditionalColor";

const DepositDetail = ({ deposit, t }) => {
  const color = getColor(deposit.status);
  const date = moment(new Date(deposit.date * 1000)).format("DD MMM Y hh:mm A");

  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1 overflow-hidden">
            <Badge className={"bg-" + color + " mb-2"}>{deposit.status}</Badge>
            <h5 className="text-truncate font-size-15 text-success">
              {`${t("ID")}: `}
            </h5>
            <h5 className="text-truncate font-size-15">{deposit.depositId}</h5>
            <h5 className="text-truncate font-size-15 text-success mt-4">
              {`${t(`Description`)}: `}
            </h5>
            <h5 className="text-muted">{deposit.description || "----"}</h5>
          </div>
        </div>

        <h5 className="font-size-15 mt-4 text-success">{t("Details")} :</h5>

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                {t("Date")}
              </h5>
              <p className="text-muted mb-0">{date}</p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <h5 className="font-size-14 mt-4">
              <i className="bx bx-money me-1 text-primary" /> {t("Amount")}
            </h5>
            <p className="text-muted mb-0">{`${deposit.amount} ${t("SAR")}`}</p>
          </Col>

          <Col sm="4" xs="6">
            <h5 className="font-size-14 mt-4">
              <i className="bx bx-user-check me-1 text-primary" />{" "}
              {t("Submitted By")}
            </h5>
            <div
              className="d-inline-block btn"
              id={"member" + deposit.submittedBy.name}
            >
              <div className="avatar-xs">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    color +
                    " text-" +
                    color +
                    " font-size-16"
                  }
                >
                  {deposit.submittedBy.name.charAt(0)}
                </span>
                <UncontrolledTooltip
                  placement="top"
                  target={"member" + deposit.submittedBy.name}
                >
                  {deposit.submittedBy.name}
                </UncontrolledTooltip>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

DepositDetail.propTypes = {
  t: PropTypes.any,
  deposit: PropTypes.object,
};

export default withTranslation()(DepositDetail);

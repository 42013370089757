import {
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_FAIL,
  GET_ACTIVITY_LOGS_SUCCESS, GET_ACTIVITY_LOG_BY_ID, GET_ACTIVITY_LOG_BY_ID_FAIL,
  GET_ACTIVITY_LOG_BY_ID_SUCCESS
} from "./actionTypes";

export const getActivityLogs = () => ({
  type: GET_ACTIVITY_LOGS,
});

export const getActivityLogsSuccess = logs => ({
  type: GET_ACTIVITY_LOGS_SUCCESS,
  payload: logs,
});

export const getActivityLogsFail = error => ({
  type: GET_ACTIVITY_LOGS_FAIL,
  payload: error.response.data.messages,
});

export const getTransactionById = logId => ({
  type: GET_ACTIVITY_LOG_BY_ID,
  payload: logId,
});

export const getActivityLogsByIdSuccess = log => ({
  type: GET_ACTIVITY_LOG_BY_ID_SUCCESS,
  payload: log,
});

export const getActivityLogsByIdFail = error => ({
  type: GET_ACTIVITY_LOG_BY_ID_FAIL,
  payload: error.response.data.messages,
});

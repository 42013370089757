import { useFormik } from "formik";
import { truncate } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MetaTags from "react-meta-tags";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import {
  addCredits as onAddCredits,
  sendOtp as onSendOtp,
  verifyOtp as onVerifyOtp,
  resetTransaction as onReset,
  resetError as onResetError,
} from "store/actions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// phone country dropdown localization
import ar from "react-phone-input-2/lang/ar.json";

const AddCredits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    transactionDetails,
    otpVerified,
    transactionId,
    error,
    creditsAdded,
    successMsg,
    layoutDir,
  } = useSelector(state => ({
    transactionDetails: state.Transactions.transactionDetails,
    otpVerified: state.Transactions.otpVerified,
    transactionId: state.Transactions.transactionId,
    error: state.Transactions.error,
    successMsg: state.Transactions.successMsg,
    creditsAdded: state.Transactions.creditsAdded,
    layoutDir: state.Layout.layoutDir,
  }));
  toastr.options.positionClass = "toast-bottom-right";
  const [customerDetails, setCustomerDetails] = useState({});
  const [phone, setPhone] = useState("");
  const [checkLoading, setCheckLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initiate, setInitiate] = useState(true);
  const [phoneMsg, setPhoneMsg] = useState("");
  const otpRef = useRef(null);
  const amountRef = useRef(null);
  const [localization, setLocalization] = useState(layoutDir);
  const initialValues = {
    otp: "",
    transactionId: "",
    otpVerified: false,
    amount: "",
  };
  const onSubmit = () => addCredits();

  const validationSchema = yup.object({
    otp: yup.number().required(t("required-otp")),
    amount: yup.number().min(30).required("required-amount"),
  });
  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  async function sendOTP() {
    setCheckLoading(true);
    const payload = { type: "credit top-up" };
    payload.mobilePhone = phone.toString().slice(3);
    dispatch(onSendOtp(payload));
    form.values.transactionId = transactionId;
  }

  async function verifyOTP() {
    setVerifyLoading(true);
    const payload = { inputOtp: form.values.otp.toString() };
    dispatch(onVerifyOtp({ transactionId, payload }));
    form.values.otpVerified = otpVerified;
  }

  async function addCredits() {
    setConfirmLoading(true);
    const payload = {
      amount: form.values.amount,
      transactionId: transactionId,
    };
    dispatch(onAddCredits(payload));
  }

  function resetValues() {
    form.resetForm();
    setCustomerDetails({});
    setPhone("966");
    setVerifyLoading(false);
    setCheckLoading(false);
    setConfirmLoading(false);
  }

  useEffect(() => {
    if (error) {
      toastr.error(error);
      setVerifyLoading(false);
      setCheckLoading(false);
      setConfirmLoading(false);
      // resetValues();
      // dispatch(onReset());
    }
    if (successMsg) {
      toastr.success(t(`${successMsg}`));
      if (transactionId) {
        setCheckLoading(false);
        otpRef.current.focus();
      }
      if (otpVerified) {
        setVerifyLoading(false);
        amountRef.current.focus();
      }
      if (creditsAdded) {
        setConfirmLoading(false);
      }
    }
  }, [error, successMsg]);

  useEffect(() => {
    setCustomerDetails({
      firstName: transactionDetails?.firstName,
      lastName: transactionDetails?.lastName,
      mobilePhone: phone,
    });
    if (creditsAdded) {
      resetValues();
      dispatch(onReset());
      setInitiate(false);
    }
    return () => {
      setCustomerDetails({});
    };
  }, [transactionDetails, creditsAdded, dispatch]);

  // TODO - fix
  useEffect(() => {
    setLocalization(layoutDir);
  }, [layoutDir]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("add-balance")} | {t("gazal")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t("customers")}
            breadcrumbItem={t("add-balance")}
          />
          <Row>
            <Col sm={4}>
              <Card style={{ height: "90%" }}>
                <CardBody>
                  <CardTitle className="mb-4 mt-2">
                    {t("personal-information")}
                  </CardTitle>

                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{t("first-name")} :</th>
                          <td>
                            {customerDetails.firstName
                              ? customerDetails.firstName
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("last-name")} :</th>
                          <td>
                            {customerDetails.lastName
                              ? customerDetails.lastName
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("phone")} :</th>
                          <td>
                            {customerDetails.mobilePhone
                              ? customerDetails.mobilePhone
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={8}>
              <Card style={{ height: "90%" }}>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row className="row mb-4">
                      <Col className="col-sm-4">{t("balance-service")}</Col>
                      {/* <Col className="col-sm-8 d-flex align-items-center justify-content-end mb-4"> */}
                      <Col xs={4} sm={6}></Col>
                      <Col
                        xs={1}
                        sm={2}
                        className="p-0 d-flex align-items-center justify-content-between"
                      >
                        {initiate ? (
                          <button
                            id={"clear-all-btn"}
                            type="button"
                            // className={`btn btn-${
                            //   transactionId
                            //     ? "outline-secondary"
                            //     : "outline-light"
                            // } w-sm`}
                            className="btn btn-light w-sm"
                            onClick={() => {
                              resetValues();
                              dispatch(onReset());
                              setInitiate(false);
                            }}
                            // disabled={!transactionId}
                          >
                            <span
                              className={
                                "text-decoration-underline" +
                                (!transactionId && " text-dark")
                              }
                            >
                              {/* Clear */}
                              <i className="mdi mdi-close font-size-14" />
                              {t("clear")}
                            </span>
                            <UncontrolledTooltip
                              placement="top"
                              target={"clear-all-btn"}
                            >
                              {t("reset-form")}
                            </UncontrolledTooltip>
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              id={"init-btn"}
                              className="btn btn-success w-sm"
                              onClick={() => setInitiate(true)}
                            >
                              {/* <span> */}
                              <i className="mdi mdi-plus font-size-14" />
                              {/* </span> */}
                              {/* <p className="ml-2"> */}
                              {t("add")}
                              {/* </p> */}
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={"init-btn"}
                            >
                              {t("start-service")}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardTitle>

                  <Row>
                    <Form onSubmit={form.handleSubmit}>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          {t("phone")}
                        </Label>

                        {/* Add localization */}
                        <Col xs={9} sm={8}>
                          <div style={{ direction: "ltr !important" }}>
                            {localization === "rtl" ? (
                              <PhoneInput
                                localization={ar}
                                country={"sa"}
                                inputProps={{
                                  name: "mobilePhone",
                                  autoFocus: true,
                                }}
                                inputStyle={{
                                  width: "100%",
                                }}
                                containerStyle={{ direction: "ltr" }}
                                value={phone}
                                onChange={value => {
                                  setPhone(value);
                                }}
                                onKeyDown={e => e.key === "Enter" && sendOTP()}
                                disabled={!initiate || transactionId}
                              />
                            ) : (
                              <PhoneInput
                                country={"sa"}
                                inputProps={{
                                  name: "mobilePhone",
                                  autoFocus: true,
                                }}
                                inputStyle={{
                                  width: "100%",
                                }}
                                value={phone}
                                onChange={value => {
                                  setPhoneMsg("");
                                  setPhone(value);
                                }}
                                onKeyDown={e => e.key === "Enter" && sendOTP()}
                                disabled={!initiate || transactionId}
                                // isValid={(value, country) => {
                              />
                            )}
                          </div>
                        </Col>
                        <Col xs={1} sm={2} className="p-0">
                          <button
                            type="button"
                            className={`btn btn-${
                              transactionId ? "success" : "primary"
                            } w-sm d-flex align-items-center justify-content-center`}
                            onClick={sendOTP}
                            // disabled={checkLoading}
                            disabled={
                              transactionId || checkLoading || !initiate
                            }
                          >
                            {checkLoading ? (
                              <Spinner size={"sm"} color="light" />
                            ) : transactionId ? (
                              <i className="font-size-20 align-middle me-1 bx bx-comment-check" />
                            ) : (
                              t("send-btn")
                            )}
                          </button>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          {t("otp")}
                        </Label>
                        <Col xs={9} sm={8}>
                          <Input
                            innerRef={otpRef}
                            type="number"
                            className="form-control"
                            placeholder={t("enter-otp")}
                            autoFocus
                            disabled={
                              otpVerified || (transactionId ? false : true)
                            }
                            name="otp"
                            {...form.getFieldProps("otp")}
                            invalid={
                              form.touched.otp && form.errors.otp ? true : false
                            }
                            onKeyDown={e => e.key === "Enter" && verifyOTP()}
                          />
                          {form.touched.otp && form.errors.otp ? (
                            <FormFeedback type="invalid">
                              {form.errors.otp}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col xs={1} sm={2} className="p-0">
                          <button
                            type="button"
                            className={`btn btn-${
                              otpVerified ? "success" : "primary"
                            } w-sm d-flex align-items-center justify-content-center`}
                            disabled={
                              (!verifyLoading &&
                                (transactionId ? false : true)) ||
                              otpVerified
                            }
                            onClick={verifyOTP}
                          >
                            {verifyLoading ? (
                              <Spinner size={"sm"} color="light" />
                            ) : otpVerified ? (
                              <i className="font-size-20 align-middle me-1 bx bx-comment-check" />
                            ) : (
                              t("verify-btn")
                            )}
                          </button>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label className="col-sm-2 col-form-label">
                          {t("amount")}
                        </Label>
                        <Col xs={9} sm={8}>
                          <Input
                            type="number"
                            innerRef={amountRef}
                            className="form-control"
                            placeholder={t("enter-amount")}
                            disabled={otpVerified ? false : true}
                            name="amount"
                            {...form.getFieldProps("amount")}
                            invalid={
                              form.touched.amount && form.errors.amount
                                ? true
                                : false
                            }
                          />
                          {form.touched.amount && form.errors.amount ? (
                            <FormFeedback type="invalid">
                              {form.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col xs={1} sm={2} className="p-0">
                          <button
                            type="submit"
                            className="btn btn-primary w-sm d-flex align-items-center justify-content-center"
                            disabled={
                              !confirmLoading && otpVerified ? false : true
                            }
                          >
                            {confirmLoading ? (
                              <Spinner size={"sm"} color="light" />
                            ) : (
                              t("confirm-btn")
                            )}
                          </button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {creditsAdded ? (
            <SweetAlert
              title={t("balance-added")}
              success
              timeout={2000}
              showConfirm={false}
              onConfirm={() => {
                setsuccess_msg(false);
              }}
            ></SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCredits;

import Changepw from "pages/AuthenticationInner/Changepw";
import Dashboard from "pages/Dashboard/index";
import UsersPage from "pages/Reseller/Users";
import AddCredits from "pages/Transactions/AddCredits";
import TransactionsList from "pages/Transactions/TransactionsList";
import Pages404 from "pages/Utility/pages-404";
import React from "react";
import { Redirect } from "react-router-dom";

const commonRoutes = [
  {
    path: "/password",
    component: Changepw,
    name: "settings",
    icon: "bx bx-slider-alt",
    layout: "/reseller",
  },
  {
    path: "/add-balance",
    component: AddCredits,
    name: "add-balance",
    icon: "bx bx-money",
    layout: "/reseller",
  },
  {
    path: "/transactions",
    component: TransactionsList,
    name: "transactions",
    icon: "bx bx-transfer",
    layout: "/reseller",
  },
  {
    path: "/",
    component: Pages404,
    name: "NOT FOUND",
    icon: "bx bx-transfer",
    layout: "/gazal",
    hidden: true,
  },
];

const getResellerRoutes = () => {
  const user = window.localStorage.getItem("role");
  if (user !== null && user === "reseller_admin") {
    let routes = [
      {
        path: "/dashboard",
        component: Dashboard,
        name: "dashboard",
        layout: "/reseller",
        icon: "bx bx-home-circle",
      },
      {
        path: "/users",
        component: UsersPage,
        name: "users",
        layout: "/reseller",
        icon: "bx bx-group",
      },
    ];
    return routes.concat(commonRoutes);
  } else {
    return commonRoutes;
  }
};

export default getResellerRoutes;

/**
 * TODO
 * 1. Add following routes
 * - Deposits
 * - Withdrawals
 * - Vouchers
 * - Logs
 * - Settings
 * - Profile
 */

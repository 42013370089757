import {
  GET_RESELLERS_LIST,
  GET_RESELLERS_LIST_SUCCESS,
  GET_RESELLERS_LIST_FAIL,
  ADD_RESELLER,
  ADD_RESELLER_SUCCESS,
  ADD_RESELLER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  resellers: [],
  resellerDetails: {},
  error: "",
  isLoading: false,
};

const Resellers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RESELLERS_LIST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case GET_RESELLERS_LIST_SUCCESS:
      return {
        ...state,
        resellers: action.payload,
        isLoading: false,
      };
    case GET_RESELLERS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case ADD_RESELLER:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case ADD_RESELLER_SUCCESS:
      return {
        ...state,
        resellers: [...state.resellers, action.payload],
        isLoading: false,
      };
    case ADD_RESELLER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default Resellers;

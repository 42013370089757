import { call, put, takeEvery } from "redux-saga/effects";
import { addReseller, getResellers as getAllResellers } from "helpers/backend_helper";
import { getResellersSuccess, getResellersFail, addResellerFail, addResellerSuccess } from "./actions";

import { ADD_RESELLER, GET_RESELLERS_LIST } from "./actionTypes";

function* fetchResellers() {
  try {
    const response = yield call(getAllResellers);
    yield put(getResellersSuccess(response.data));
  } catch (error) {
    // TODO - refactor
    let errorMessage = "Something went wrong";
    if (error.response?.data?.messages) {
      errorMessage = error.response?.data?.messages;
    }
    yield put(getResellersFail(errorMessage));
  }
}

function* onAddReseller({ payload: reseller }) {
  try {
    yield call(addReseller, reseller);
    yield put(addResellerSuccess(reseller));
  } catch (error) {
    yield put(addResellerFail(error.response.data.messages));
  }
}

function* resellersSaga() {
  yield takeEvery(GET_RESELLERS_LIST, fetchResellers);
  yield takeEvery(ADD_RESELLER, onAddReseller);
}

export default resellersSaga;

//  ? Get DEPOSITS
export const GET_DEPOSITS = "GET_DEPOSITS";
export const GET_DEPOSITS_SUCCESS = "GET_DEPOSITS_SUCCESS";

// ? Get Deposit Details
export const GET_DEPOSIT_DETAILS = "GET_DEPOSIT_DETAILS";
export const GET_DEPOSIT_DETAILS_SUCCESS = "GET_DEPOSIT_DETAILS_SUCCESS";

// ? Create Deposits
export const CREATE_NEW_DEPOSIT = "ADD_NEW_DEPOSIT";
export const CREATE_NEW_DEPOSIT_SUCCESS = "ADD_NEW_DEPOSIT_SUCCESS";

// ? Delete Deposits
export const DELETE_DEPOSIT = "DELETE_DEPOSIT";
export const DELETE_DEPOSIT_SUCCESS = "DELETE_DEPOSIT_SUCCESS";

// ? failure
export const DEPOSITS_FAILURE = "DEPOSITS_FAILURE";

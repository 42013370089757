import {
  GET_DEPOSITS,
  GET_DEPOSITS_SUCCESS,
  GET_DEPOSIT_DETAILS,
  GET_DEPOSIT_DETAILS_SUCCESS,
  CREATE_NEW_DEPOSIT,
  CREATE_NEW_DEPOSIT_SUCCESS,
  DELETE_DEPOSIT,
  DELETE_DEPOSIT_SUCCESS,
  DEPOSITS_FAILURE,
} from "./actionTypes";

export const handleDepositFailure = error => ({
  type: DEPOSITS_FAILURE,
  payload: error,
});

export const getAllDeposits = (resellerId, lastItem, pageSize) => ({
  type: GET_DEPOSITS,
  resellerId,
  lastItem,
  pageSize,
});

export const getAllDepositsSuccess = ({ deposits, lastItem, hasMore }) => ({
  type: GET_DEPOSITS_SUCCESS,
  payload: { deposits, lastItem, hasMore },
});

export const getDepositDetails = depositId => ({
  type: GET_DEPOSIT_DETAILS,
  depositId,
});

export const getDepositDetailsSuccess = depositDetails => ({
  type: GET_DEPOSIT_DETAILS_SUCCESS,
  payload: depositDetails,
});

export const createNewDeposit = (resellerId, deposit) => ({
  type: CREATE_NEW_DEPOSIT,
  payload: { deposit, resellerId },
});

export const createNewDepositSuccess = deposit => ({
  type: CREATE_NEW_DEPOSIT_SUCCESS,
  payload: deposit,
});

export const deleteDeposit = deposit => ({
  type: DELETE_DEPOSIT,
  payload: deposit,
});

export const deleteDepositSuccess = deposit => ({
  type: DELETE_DEPOSIT_SUCCESS,
  payload: deposit,
});

import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import "./assets/scss/theme.scss";

// Import Routes all
import { getRoutes, publicRoutes, authRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/AuthMiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "./store/auth/profile/actions";
import { getToken } from "./store/auth/login/actions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

const App = props => {
  const { isLoading, layoutDir } = useSelector(state => ({
    isLoading: state.Profile.isLoading,
    layoutDir: state.Layout.layoutDir,
  }));
  const dispatch = useDispatch();

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  const accessToken = localStorage.getItem("accessToken");
  const path = window.location.pathname.split('/')[2];
  useEffect(() => {
    if (accessToken) {
      // TODO - continue this
      dispatch(getProfile());
    }
    if ((!accessToken) && path !== 'login') {
      window.location.replace(`/reseller/login`);
    }
  }, [accessToken]);

  useEffect(() => {
    // TODO - continue this
    // dispatch(getToken());
    document.body.dir = layoutDir;
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      ) : (
        <Router>
          <Switch>
            {getRoutes().map((route, idx) => {
              return (
                <Authmiddleware
                  path={route.layout + route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              );
            })}
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
          </Switch>
        </Router>
      )}
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

import firebase from "firebase/compat/app";

// Add the Firebase products to be used
import "firebase/compat/auth";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("uid", user.uid);
        } else {
          localStorage.removeItem("uid");
        }
      });
    }
  }

  getNewIdToken = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          resolve(idToken);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  }

  /**
   * get token
   */
  getCurrentIdToken = () => {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(token => {
            if (localStorage.getItem("accessToken") !== token) {
              localStorage.setItem("accessToken", token);
            }
            resolve(token);
            return token;
          });
      } else {
        const token = localStorage.getItem("accessToken");
        if (token) {
          resolve(token);
        }
        reject();
      }
    });
  };

  /**
   * Update user password
   */
  updatePassword = (email, oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(email, oldPassword)
        )
        .then(
          () => {
            if (oldPassword === newPassword) {
              const error = {
                code: "new-password-duplicate",
              };
              return reject(error);
            }
            firebase
              .auth()
              .currentUser.updatePassword(newPassword)
              .then(() => {
                // Update successful.
                return resolve(firebase.auth().currentUser);
              })
              .catch(error => {
                // An error ocurred
                // ...
                return reject(error);
              });
          },
          error => {
            reject(error);
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(error);
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      hd: "gazal.app",
    });

    return new Promise((resolve, reject) => {
      if (!!provider) {
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(user => {
            resolve(firebase.auth().currentUser);
          })
          .catch(error => {
            firebase.auth().signOut();
            reject(error);
          });
      } else {
        reject(error);
      }
    });
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };

/* GET RESELLERS */
export const GET_RESELLERS_LIST = "GET_RESELLERS_LIST";
export const GET_RESELLERS_LIST_SUCCESS = "GET_RESELLERS_LIST_SUCCESS";
export const GET_RESELLERS_LIST_FAIL = "GET_RESELLERS_LIST_FAIL";

/* GET RESELLER */
export const GET_RESELLER = "GET_RESELLER";
export const GET_RESELLER_SUCCESS = "GET_RESELLER_SUCCESS";
export const GET_RESELLER_FAIL = "GET_RESELLER_FAIL";

/* ADD RESELLER */
export const ADD_RESELLER = "ADD_RESELLER";
export const ADD_RESELLER_SUCCESS = "ADD_RESELLER_SUCCESS";
export const ADD_RESELLER_FAIL = "ADD_RESELLER_FAIL";
import PropTypes from "prop-types";
import React from "react";
import { formatDate as handleValidDate } from "utils";

import { Col, Card, CardBody, Table, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";

//Simple bar
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

const Transactions = ({ data }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{t("recent-transactions")}</h4>

          <SimpleBar style={{ maxHeight: "250px" }}>
            {data ? (
              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <tbody>
                    {data.slice(0, 5).map((transaction, i) => (
                      <tr key={transaction.transactionId}>
                        {/* <td style={{ width: "50px" }}>
                          <div className="font-size-22 text-primary">
                            <i className="bx bx-down-arrow-circle" />
                          </div>
                        </td> */}

                        <td>
                          <div>
                            <h5 className="font-size-14 mb-1">
                              {transaction.employee}
                            </h5>
                            <p className="text-muted mb-0">
                              {handleValidDate(transaction.createdAt)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            {transaction.amountPaid ? (
                              <h5 className="font-size-14 mb-0">
                                {transaction.amountPaid.toFixed(2)} {t("SAR")}
                              </h5>
                            ) : (
                              "---"
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        {/* <Row> */}
                        <div className="text-end">
                          <Link
                            to="/reseller/transactions"
                            className="text-success"
                          >
                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                            {t("View more")}
                          </Link>
                        </div>
                        {/* </Row> */}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : (
              <Col className="d-flex align-items-center justify-content-center ">
                <Spinner size="md" className="mb-4" />
              </Col>
            )}
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

Transactions.propTypes = {
  t: PropTypes.any,
  data: PropTypes.object,
};

export default Transactions;

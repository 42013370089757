import React from "react";
import { Redirect } from "react-router-dom";
import DepositOverview from "pages/deposits/depositOverview";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import LoginGazal from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import LoginReseller from "../pages/AuthenticationInner/Login";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import Register2 from "../pages/AuthenticationInner/Register2";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import getGazalRoutes from "./gazalRoutes";
import getResellerRoutes from "./resellerRoutes";

const publicRoutes = [
  { path: "/reseller/forgot-password", component: ForgetPwd },
  { path: "/reseller/login", component: LoginReseller },
  { path: "/gazal/login", component: LoginGazal },
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
  {
    path: "/",
    // exact: true,
    component: () => <Redirect to="/reseller/login" />,
  },
  {
    path: "/login",
    // exact: true,
    component: () => <Redirect to="/reseller/login" />,
  },
];

const getRoutes = () => {
  const type = window.localStorage.getItem("type");
  if (type !== null) {
    return type === "gazal" ? getGazalRoutes() : getResellerRoutes();
  } else {
    return [];
  }
};

export { publicRoutes, getRoutes };


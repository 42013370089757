import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_DASHBOARD_DATA } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import { getDashboardData } from "helpers/backend_helper";

function* getData({ payload: resellerId }) {
  try {
    const response = yield call(getDashboardData, resellerId);
    yield put(apiSuccess(GET_DASHBOARD_DATA, response));
  } catch (error) {
    yield put(apiFail(GET_DASHBOARD_DATA, error));
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_DASHBOARD_DATA, getData);
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;

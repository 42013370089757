import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/gazal/logo.png";
import logolight from "../../assets/images/gazal/logo-white.png";
import CarouselPage from "./CarouselPage";

import { useTranslation } from "react-i18next";

import { loginUser } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// RESELLER LOGIN
const Login = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("enter-email")),
      password: Yup.string().required(t("enter-password")),
    }),
    onSubmit: values => {
      setDisabled(true);
      dispatch(loginUser(values, props.history));
      setDisabled(false);
    },
  });

  const onCancelAlert = () => {
    setShow(false);
  };

  useEffect(() => {
    if (error) {
      setShow(true);
    }
  }, [error]);

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>
            {t("login-page-title")} - {t("app-title")}
          </title>
        </MetaTags>
        <Container fluid className="p-0">
          {show ? (
            <SweetAlert
              title={t(`${error}`)}
              warning
              timeout={2000}
              showConfirm={false}
              onCancel={onCancelAlert}
              closeOnClickOutside={true}
              showCloseButton={true}
              onConfirm={() => {
                // TODO- add confirm handler
              }}
            ></SweetAlert>
          ) : null}
          <Row className="g-0">
            <Col xl={9}>
              <CarouselPage />
            </Col>

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/reseller/login" className="d-block auth-logo">
                        <img
                          // style={{ float: "right" }}
                          src={logodark}
                          alt=""
                          height="90"
                          className="auth-logo-dark"
                        />
                        <img
                          // style={{ float: "right" }}
                          src={logolight}
                          alt=""
                          height="90"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">{t("welcome-back")}</h5>
                        <p className="text-muted">{t("login-subtitle")}</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("email-label")}
                            </Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder={t("enter-email")}
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              {t("password-label")}
                            </Label>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder={t("enter-password")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                              disabled={disabled}
                            >
                              {t("login-btn")}
                            </button>
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            <Link
                              to="/reseller/forgot-password"
                              className="text-primary"
                            >
                              <span className="m-1">
                                <i className="bx bx-lock" />
                              </span>
                              {t("forgot-password")}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {t("gazal")}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Login;

Login.propTypes = {
  history: PropTypes.object,
};

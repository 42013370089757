import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  getStateColor as getBadgeColor,
  formatDate as handleValidDate,
} from "utils";

import {
  getResellerUsers as onGetResellerUsers,
  updateResellerUser as onUpdateResellerUser,
  updateResellerUserState as onUpdateResellerUserState,
} from "store/actions";
import "toastr/build/toastr.min.css";

import toastr from "toastr";
import {
  UncontrolledDropdown,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";
import Datatable from "components/CommonForBoth/Datatable";

// validation
import * as Yup from "yup";
import { useFormik } from "formik";

const UsersList = ({ userList, close, refetch }) => {
  toastr.options.positionClass = "toast-bottom-right";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { error, profile, updateLoading, success } = useSelector(state => ({
    profile: state.Profile.profile,
    updateLoading: state.ResellerUsers.updateLoading,
    error: state.ResellerUsers.error,
    success: state.ResellerUsers.success,
  }));
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const editRef = useRef(null);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (user && user.firstName) || "",
      lastName: (user && user.lastName) || "",
      email: (user && user.email) || "",
      state: (user && user.state) || "",
      role: (user && user.role) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("required-first-name")),
      lastName: Yup.string().required(t("required-last-name")),
      email: Yup.string().required(t("required-email")),
      state: Yup.string().required(t("required-state")),
      role: Yup.string().required(t("required-role")),
    }),
    onSubmit: values => {
      const updateUser = {
        uid: user.uid,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        state: values.state,
        role: values.role,
      };

      // update user
      dispatch(onUpdateResellerUser(updateUser));
      validation.resetForm();
      toggle();
    },
  });

  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setUser(null);
    } else {
      setModal(true);
      if (editRef && editRef.current) {
        editRef.current.focus();
      }
    }
  };

  const handleUserClick = arg => {
    const user = arg;

    setUser({
      uid: user.uid,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      state: user.state,
      role: user.role,
      validity: user.validity,
    });

    toggle();
  };

  // TODO refetch mechanism
  const onClickState = (user, state) => {
    const data = {
      uid: user.uid,
      state,
    };
    dispatch(onUpdateResellerUserState(data));
  };

  useEffect(() => {
    setLoading(updateLoading);
    if (success) {
      toastr.success(t("user-updated"));
      refetch();
    }
  }, [updateLoading]);

  useEffect(() => {
    if (error) {
      toastr.error(error); // TODO localization
    }
    if (success) {
      toastr.success(success);
      close();
    }
  }, [error, success]);

  const sorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ];
  const userListColumns = [
    {
      dataField: "img",
      isDummyField: true,
      text: "#",
      formatter: (cellContent, user) => (
        <>
          {/* TODO - replace with user.photoUrl */}
          {/* {!user.photoUrl ? ( */}
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.firstName.toUpperCase().charAt(0)}
            </span>
          </div>
          {/* ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                // src={images[user.photoUrl]}
                alt=""
              />
            </div>
          )} */}
        </>
      ),
    },
    {
      text: t("first-name"),
      dataField: "firstName",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.firstName}
            </Link>
          </h5>
        </>
      ),
    },
    {
      text: t("last-name"),
      dataField: "lastName",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.lastName}
            </Link>
          </h5>
        </>
      ),
    },
    {
      text: t("email"),
      dataField: "email",
      sort: true,
    },
    {
      text: t("state"),
      dataField: "state",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <Badge
            className={"font-size-12 badge-soft-" + getBadgeColor(user.state)}
            color={getBadgeColor(user.state)}
            pill
          >
            {t(`${user.state}`) || t("pending")}
          </Badge>
        </>
      ),
    },
    {
      text: t("role"),
      dataField: "role",
      sort: true,
    },
    {
      text: t("creation-date"),
      dataField: "createdAt",
      sort: true,
      formatter: (cellContent, user) => (
        <>{user.createdAt ? handleValidDate(user.createdAt) : "---"}</>
      ),
    },
    {
      text: t("joined"),
      dataField: "activatedAt",
      sort: true,
      formatter: (cellContent, user) => (
        <>{user.activatedAt ? handleValidDate(user.activatedAt) : "---"}</>
      ),
    },
    // TODO - complete
    {
      text: t("valid-until"),
      dataField: "expiresAt",
      sort: true,
      formatter: (cellContent, user) => (
        <>{user.expiresAt ? handleValidDate(user.expiresAt) : "---"}</>
      ),
    },
    {
      text: t("action"),
      dataField: "menu",
      isDummyField: true,
      formatter: (cellContent, user) => (
        <>
          {loading ? (
            <Spinner size={"sm"} color="dark" />
          ) : user.uid !== profile.uid ? (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#" onClick={() => handleUserClick(user)}>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  {t("edit")}
                </DropdownItem>
                {/* TODO complete */}
                {user.state === "active" ? (
                  <DropdownItem onClick={() => onClickState(user, "disabled")}>
                    <i className="bx bx-block font-size-16 text-danger me-1" />{" "}
                    {t("disable")}
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={() => onClickState(user, "active")}>
                    <i className="bx bx-user-check font-size-16 text-primary me-1" />{" "}
                    {t("enable")}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            "---"
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      {userList && userList.length ? (
        <Datatable
          title={t("users")}
          columns={userListColumns}
          data={userList}
          sorted={sorted}
        />
      ) : (
        <p>{t("no-users-found")}</p>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("edit-user")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row form>
              <Col xs={12}>
                <div className="mb-3">
                  <Label htmlFor="firstNameInput">{t("first-name")}</Label>
                  <Input
                    // autoFocus
                    innerRef={editRef}
                    type="text"
                    className="form-control"
                    id="firstNameInput"
                    name="firstName"
                    placeholder={t("first-name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label htmlFor="lastNameInput">{t("last-name")}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="lastNameInput"
                    name="lastName"
                    placeholder={t("last-name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                    invalid={
                      validation.touched.lastName && validation.errors.lastName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label htmlFor="floatingemailInput">
                    {t("email-address")}
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="floatingemailInput"
                    name="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label htmlFor="floatingSelectGrid">{t("role")}</Label>
                  <select
                    defaultValue=""
                    className="form-select"
                    name="role"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.role.toLowerCase() || ""}
                    // invalid={
                    //   validation.touched.role && validation.errors.role
                    //     ? true
                    //     : false
                    // }
                  >
                    <option value="" label={t("select-role")}>
                      {t("select-role")}
                    </option>
                    <option value="reseller_admin">{t("admin")}</option>
                    <option value="reseller_employee">{t("employee")}</option>
                  </select>
                  {validation.touched.role && validation.errors.role ? (
                    <FormFeedback type="invalid">
                      {validation.errors.role}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* TODO - Complete */}
                {/* <div className="mb-3">
                  <Label className="form-label">Account Validity</Label>
                  <Input
                    name="dueDate"
                    type="date"
                    format="YYYY/MM/DD"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.validity && validation.errors.validity
                        ? true
                        : false
                    }
                    value={validation.values.validity || ""}
                  ></Input>
                  {validation.touched.validity && validation.errors.validity ? (
                    <FormFeedback type="invalid">
                      {validation.errors.validity}
                    </FormFeedback>
                  ) : null}
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    // disabled={!validation.isValid}
                  >
                    {t("save-btn")}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

UsersList.propTypes = {
  userList: PropTypes.array,
  close: PropTypes.func,
  refetch: PropTypes.func,
};

export default UsersList;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import generator from "generate-password";

import { addReseller as onAddReseller } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Col,
  Row,
  CardBody,
  Form,
  InputGroup,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
//Import css
import "flatpickr/dist/themes/material_blue.css";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";

import Flatpickr from "react-flatpickr";
import toastr from "toastr";
import moment from "moment";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const AddReseller = ({ close }) => {
  toastr.options.positionClass = "toast-bottom-right";
  const dispatch = useDispatch();

  const { profile } = useSelector(state => ({
    profile: state.Profile.profile,
  }));

  const [togglePassSwitch, settogglePassSwitch] = useState(false);
  const [toggleInviteSwitch, settoggleInviteSwitch] = useState(true);
  const [toggleMessage, setToggleMessage] = useState(false);
  const [months, setMonths] = useState(6);

  const minValidity = moment().add(months, "month");
  const [validityDate, setValidityDate] = useState(minValidity);
  const [isRandom, setIsRandom] = useState(true);

  const [password, setPassword] = useState("");
  const [randomPassword, setRandomPassword] = useState("");

  const [values, setValues] = useState({
    name: "",
    city: "",
    country: "",
    owner: "",
    logo: "",
    previewImage: "",
    progress: 0,
    message: "",
  });

  const calculateExpiryDate = () => {
    const today = moment();
    const endDate = today.add(months + 1, "month");
    setValidityDate(endDate);
    return endDate;
  };

  const handlePasswordToggle = () => {
    settogglePassSwitch(!togglePassSwitch);
    setIsRandom(!isRandom);
    if (isRandom) {
      const rand = generator.generate({
        length: 12,
        numbers: true,
        uppercase: true,
      });
      setRandomPassword(rand);
    }
  };

  // TODO - password and random password validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: values.name,
      city: values.city,
      // country: values.country,
      owner: values.owner,
      logo: values.logo,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please give a Reseller name"),
      owner: Yup.string().required("Reseller owner name "),
      city: Yup.string(),
      // country: Yup.string(),
      logo: Yup.mixed(),
    }),
    onSubmit: values => {
      dispatch(onAddReseller(values));
      toastr.success("User successfully created");
      close();
    },
  });

  return (
    <Card>
      <CardBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md={4} className="dropzone-previews mb-3">
              {values.logo ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        {/* <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={values.logo.name}
                            src={values.logo.preview}
                          /> */}
                        {values.previewImage && (
                          <div>
                            <img
                              // className="avatar-md"
                              height="150"
                              width="200"
                              src={values.previewImage}
                              alt=""
                            />
                          </div>
                        )}
                      </Col>
                      <Col>
                        {/* <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {values.logo.name}
                            </Link> */}
                        <p className="mb-0">
                          <strong>{values.logo.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : (
                <img src="#" height="150" width="200" />
              )}
              <Input
                className="mt-3"
                name="logo"
                type="file"
                accept={["image/png", "image/jpg", "image/jpeg"]}
                onChange={event => {
                  // handleAcceptedFiles(event.target.files);
                  setValues({
                    ...values,
                    logo: event.target.files[0],
                    previewImage: URL.createObjectURL(event.target.files[0]),
                  });
                }}
                onBlur={validation.handleBlur}
              />
              {validation.touched.logo && validation.errors.logo ? (
                <FormFeedback type="invalid">
                  {validation.errors.logo}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={8}>
              <FormGroup>
                <div className="form-floating mb-3">
                  <Input
                    autoFocus
                    type="text"
                    className="form-control"
                    id="nameInput"
                    name="name"
                    placeholder="Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    // invalid={
                    //   validation.touched.name && validation.errors.name
                    //     ? true
                    //     : false
                    // }
                  />

                  <Label htmlFor="nameInput">Reseller Name</Label>
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="ownerInput"
                    name="owner"
                    placeholder="Owner Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.owner || ""}
                    invalid={
                      validation.touched.owner && validation.errors.owner
                        ? true
                        : false
                    }
                  />
                  <Label htmlFor="ownerInput">Owner Name</Label>
                  {validation.touched.owner && validation.errors.owner ? (
                    <FormFeedback type="invalid">
                      {validation.errors.owner}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    name="city"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.city || ""}
                    // invalid={
                    //   validation.touched.city && validation.errors.city
                    //     ? "true"
                    //     : "false"
                    // }
                  >
                    <option value="Riyadh" label="Riyadh">
                      Riyadh
                    </option>
                    {/* TODO replace with city */}
                    {/* <option value="admin">city</option>
                    <option value="manager">city</option>
                    <option value="employee">city</option> */}
                  </select>
                  <Label htmlFor="floatingSelectGrid">city</Label>
                  {validation.touched.city && validation.errors.city ? (
                    <FormFeedback type="invalid">
                      {validation.errors.city}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>

              <div className="d-flex align-items-center justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  disabled={!validation.isValid}
                >
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

AddReseller.propTypes = {
  close: PropTypes.func,
};

export default AddReseller;

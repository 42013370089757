import {
  ADD_CREDITS,
  ADD_CREDITS_FAIL,
  ADD_CREDITS_SUCCESS,
  CANCEL_TRANSACTION,
  CANCEL_TRANSACTION_FAIL,
  CANCEL_TRANSACTION_SUCCESS,
  GET_TRANSACTIONS_LIST,
  GET_TRANSACTIONS_LIST_FAIL,
  GET_TRANSACTIONS_LIST_SUCCESS,
  GET_TRANSACTION_FAIL,
  GET_TRANSACTION_SUCCESS,
  RESET_ERROR,
  RESET_TRANSACTION,
  SEND_OTP,
  SEND_OTP_FAIL,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  transactions: [],
  transactionDetails: {},
  error: "",
  otpVerified: false,
  creditsAdded: false,
  transactionId: "",
  successMsg: "",
  isLoading: false,
};

const Transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_LIST:
      return {
        ...state,
        transactions: action.payload,
        successMsg: "",
        error: "",
        isLoading: true,
      };
    case GET_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        transactions: action.payload.data,
        pageInfo: action.payload.pageInfo,
        isLoading: false,
      };
    case GET_TRANSACTIONS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_CREDITS:
      return {
        ...state,
        creditsAdded: false,
        error: "",
        successMsg: "",
      };
    case ADD_CREDITS_SUCCESS:
      return {
        ...INIT_STATE,
        creditsAdded: true,
        successMsg: "balance-added-successfully",
      };
    case ADD_CREDITS_FAIL:
      return {
        ...state,
        error: action.payload,
        creditsAdded: false,
      };
    case SEND_OTP:
      return {
        ...state,
        error: "",
        successMsg: "",
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        transactionDetails: action.payload.details,
        transactionId: action.payload.transactionId,
        successMsg: "otp-sent",
        error: "",
      };
    case SEND_OTP_FAIL:
      return {
        ...state,
        transactionDetails: {},
        transactionId: "",
        error: action.payload,
      };
    case VERIFY_OTP:
      return {
        ...state,
        successMsg: "",
        error: "",
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpVerified: action.payload.status === "OK" ? true : false,
        successMsg: "verification-successful",
        error: "",
      };
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CANCEL_TRANSACTION:
      return {
        ...state,
        successMsg: "",
        error: "",
      };
    case CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        successMsg: "Transaction cancelled successfully",
        data: action.payload,
      };
    case CANCEL_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_TRANSACTION:
      state = INIT_STATE;
      return state;
    case RESET_ERROR:
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export default Transactions;

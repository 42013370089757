import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const StackedColumnChart = ({ periodData, periodType }) => {
  let indexArray = [...periodData.keys()];
  const { t } = useTranslation();

  const years = indexArray.map(index => index + 2022);

  const yearNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories:
        periodType === "monthly" ? yearNames.map(year => t(`${year}`)) : years,
      labels: {
        show: true,
      },
    },
    colors: ["#556ee6", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[
          {
            data: periodData,
          },
        ]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
  periodType: PropTypes.string,
};
export default StackedColumnChart;

import usFlag from "../assets/images/flags/us.svg";
import saFlag from "../assets/images/flags/sa.svg";

const languages = {
  ar: {
    label: "العربية",
    flag: saFlag,
    dir: "rtl",
  },
  en: {
    label: "English",
    flag: usFlag,
    dir: "ltr",
  },
};

export default languages;

import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col, UncontrolledTooltip } from "reactstrap";
import { formatDate, getStateColor } from "utils";

const CardReseller = ({ resellers }) => {
  const dispatch = useDispatch();
  const getRandomColor = () => {
    const colors = ["success", "danger", "secondary"];
    const randIndex = Math.floor(Math.random() * colors.length) + 1;
    return colors[randIndex];
  };
  return (
    <React.Fragment>
      {map(resellers, (reseller, key) => (
        <Col xl="4" sm="6" key={key}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-md me-4">
                    <Link to={`/gazal/dashboard?resellerId=${reseller.resellerId}`}>
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    {/* <img src={companies[reseller.img]} alt="" height="30" /> */}
                    <span
                      className={`avatar-title rounded-circle bg-black`}
                    >
                      {reseller.name.toUpperCase().charAt(0)}
                    </span>
                  </span>
                    </Link>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15 text-dark">
                    {reseller.name}
                  </h5>

                  <div className="avatar-group">
                    {reseller.users.map(
                      (user, key) => (
                        //   !user.img || user.img !== "Null" ? (
                        <React.Fragment key={key}>
                          <div className="avatar-group-item">
                            <Link
                              to="#"
                              className="d-inline-block"
                              id={"member" + key}
                            >
                              {/* <img
                                src={images[user.img]}
                                className="rounded-circle avatar-xs"
                                alt=""
                              /> */}
                              <div className="avatar-xs">
                                <span
                                  className={`avatar-title rounded-circle bg-${getRandomColor()}`}
                                >
                                  {user.firstName.toUpperCase().charAt(0)}
                                </span>
                              </div>
                              <UncontrolledTooltip
                                placement="top"
                                target={"member" + key}
                              >
                                {`${user.firstName} ${user.lastName}`}
                              </UncontrolledTooltip>
                            </Link>
                          </div>
                        </React.Fragment>
                      )
                      //   ) : (
                      //     <React.Fragment key={key}>
                      //       <div className="avatar-group-item">
                      //         <Link
                      //           to="#"
                      //           className="d-inline-block"
                      //           id={"member" + key}
                      //         >
                      //           <div className="avatar-xs">
                      //             <span
                      //               className={
                      //                 "avatar-title rounded-circle bg-soft bg-" +
                      //                 // user.color +
                      //                 "success" +
                      //                 " text-" +
                      //                 // user.color +
                      //                 "success" +
                      //                 " font-size-16"
                      //               }
                      //             >
                      //               {reseller.name}
                      //             </span>
                      //             <UncontrolledTooltip
                      //               placement="top"
                      //               target={"member" + key}
                      //             >
                      //               {`${user.firstName} ${user.lastName}`}
                      //             </UncontrolledTooltip>
                      //           </div>
                      //         </Link>
                      //       </div>
                      //     </React.Fragment>
                      //   )
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  <Badge className={"bg-" + getStateColor(reseller.state)}>
                    {reseller.state}
                  </Badge>
                </li>

                <li className="list-inline-item me-3" id="createdAt">
                  <i className="bx bx-calendar me-1" />{" "}
                  {formatDate(reseller.createdAt)}
                  <UncontrolledTooltip placement="top" target="createdAt">
                    Joined At
                  </UncontrolledTooltip>
                </li>
                <li className="list-inline-item me-3" id="agreements">
                  <i className="mdi mdi-file-document-multiple-outline me-1" />
                  {reseller.agreements.length}
                  <UncontrolledTooltip placement="top" target="agreements">
                    Agreements
                  </UncontrolledTooltip>
                </li>
                <li className="list-inline-item me-3" id="locations">
                  <i className="mdi mdi-map-marker-multiple me-1" />
                  {reseller.locations.length}
                  <UncontrolledTooltip placement="top" target="locations">
                    Locations
                  </UncontrolledTooltip>
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardReseller.propTypes = {
  resellers: PropTypes.array,
};

export default CardReseller;

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  GET_TOKEN,
  VERIFY_INVITE_TOKEN,
} from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess, setToken } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import authMessages from "constants/authMessages";
import { verifyInviteToken } from "helpers/backend_helper";

const fireBaseBackend = getFirebaseBackend();

function* verifyInvitedUser({ payload: { uid, verifyToken } }) {
  try {
    yield call(verifyInviteToken, uid, verifyToken);
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginUser({ payload: { user, history, verifyToken } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    );

    const decoded = jwt_decode(response._delegate.accessToken);

    if (decoded.type === "gazal") {
      throw Error("Unauthorized");
    }

    localStorage.setItem("accessToken", response._delegate.accessToken);
    localStorage.setItem(`type`, `${decoded.type}`);
    localStorage.setItem(`role`, `${decoded.role}`);
    yield put(loginSuccess(decoded.user_id));
    
    let querystring = window.location.search;
    // TODO extract query and base condition on it
    if (querystring.length) {
      history.push(`/${decoded.type}/password?${querystring}`);
    } else {
      let page = decoded.role === "reseller_employee" ? "add-balance" : "dashboard";
      history.push(`/${decoded.type}/${page}`);
    }
  } catch (error) {
    let errorMessage = error.message || authMessages["general"];
    if (error.code) {
      errorMessage = authMessages[error.code];
    }
    yield put(apiError(errorMessage));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const type = localStorage.getItem("type");

    let keysToRemove = ["type", "uid", "accessToken", "role"];

    keysToRemove.forEach(key => {
      return localStorage.removeItem(key);
    });

    const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess(response));

    history.push(`/${type}/login`);
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { history } }) {
  try {
    const fireBaseBackend = getFirebaseBackend();
    const response = yield call(fireBaseBackend.socialLoginUser);
    const decoded = jwt_decode(response._delegate.accessToken);

    const type = decoded.type;
    const unauthorized = type && type !== "gazal";
    if (!type || unauthorized) {
      // TODO - account still gets created in fb auth, find a way to delete it after throwing err
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
      throw Error("This account is not authorized");
    }
    localStorage.setItem(`type`, `${decoded.type}`);
    localStorage.setItem(`role`, `${decoded.role}`);
    localStorage.setItem("accessToken", response._delegate.accessToken);

    yield put(loginSuccess(decoded.user_id));

    history.push("/gazal/transactions");
  } catch (error) {
    const errorMessage = error.message || authMessages["general"];
    yield put(apiError(errorMessage));
  }
}

function* getToken() {
  const token = localStorage.getItem("accessToken");
  if (token) {
    yield put(setToken(token));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_TOKEN, getToken);
  yield takeEvery(VERIFY_INVITE_TOKEN, verifyInvitedUser);
}

export default authSaga;

import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Card, CardBody, Spinner } from "reactstrap";

//Import Images
import defaultReseller from "../../assets/images/users/default-reseller.jpg";
import { useTranslation } from "react-i18next";

function CardUser({ data }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row>
        <Card>
          <Col lg="12">
            <CardBody>
              {data ? (
                <Row>
                  <Col lg="4">
                    <>
                      <div className="d-flex">
                        <div className="me-3 avatar-md">
                          <img
                            src={defaultReseller}
                            alt=""
                            className="img-thumbnail rounded-circle "
                          />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            <p className="mb-2">
                              {t("welcome-reseller-dashboard")}
                            </p>
                            <h5 className="mb-1">{data.profile.name}</h5>
                          </div>
                        </div>
                      </div>
                    </>
                  </Col>

                  <Col lg="6" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                      <Row>
                        <Col xs="4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              {t("total-users")}
                            </p>
                            <h5 className="mb-0">{data.users.total}</h5>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              {t("total-active")}
                            </p>
                            <h5 className="mb-0">{data.users.active}</h5>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              {t("total-disabled")}
                            </p>
                            <h5 className="mb-0">{data.users.disabled}</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  {/* <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0">
                    <Dropdown
                      isOpen={true}
                      toggle={toggleSettings}
                      className="float-end"
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        <i className="bx bxs-cog align-middle me-1" /> Setting
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Action</DropdownItem>
                        <DropdownItem href="#">Another action</DropdownItem>
                        <DropdownItem href="#">Something else</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col> */}
                </Row>
              ) : (
                <Col className="d-flex align-items-center justify-content-center ">
                  <Spinner size="md" className="mt-4 mb-4" />
                </Col>
              )}
            </CardBody>
          </Col>
        </Card>
      </Row>
    </React.Fragment>
  );
}

CardUser.propTypes = {
  data: PropTypes.object,
};

export default CardUser;

import axios from "axios";
import { getFirebaseBackend } from "./firebase_helper";
//pass new generated access token here

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  response => response,
  async function (error) {
    // const msg = error?.response?.data?.messages ?? "";
    if (
      error.response &&
      error.response.status === 401
    ) {
      let retryCount = error.config._retryCount ?? 0;
      if (retryCount > 3) {
        return Promise.reject(error);
      }
      const fireBaseBackend = getFirebaseBackend();
      const newToken = await fireBaseBackend.getNewIdToken();
      retryCount = retryCount + 1;
      error.config._retryCount = retryCount;
      const originalRequest = error.config;
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return axiosApi(originalRequest);
    }
    return Promise.reject(error);
  }
);

axiosApi.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem("accessToken");
    config.headers.Authorization = "";
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers["Accept-Language"] = localStorage.getItem("I18N_LANGUAGE");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosApi;

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

import axiosApi from "./api_helper";
import { del, get, post, put, patch } from "./api_helper";
import * as endpoints from "./endpoints_helper";

// Resellers
const getResellers = () => {
  return get(`${endpoints.RESELLERS}`);
};

const addReseller = reseller => {
  return post(`${endpoints.RESELLERS}`, reseller);
};

// Reseller Users
const getResellerUsers = resellerId => {
  return get(`${endpoints.RESELLERS}/${resellerId}/users`);
};

const getResellerUserDetails = resellerUserId => {
  get(`${endpoints.RESELLERS}/users/${resellerUserId}`);
};

const updateResellerUser = resellerUser => {
  return patch(
    `${endpoints.RESELLERS}/users/${resellerUser.uid}`,
    resellerUser
  );
};

const updateResellerUserState = (uid, state) => {
  return post(`${endpoints.RESELLERS}/users/${uid}/state`, {
    state,
  });
};

const disableResellerUser = (resellerUserId, data) => {
  post(`${endpoints.RESELLERS}/users/${resellerUserId}`, data);
};

// Users
// TODO move out of resellers into users actions
const addNewUser = data => {
  return post(`${endpoints.USERS}`, data);
};

const getCurrentUserAccount = () => {
  return get(`${endpoints.USERS}/me`);
};

// Transactions
const getTransactions = () => {
  return get(`${endpoints.TRANSACTIONS}`);
};

const getTransactionsById = transactionId => {
  return get(`${endpoints.TRANSACTIONS}/${transactionId}`);
};

const addCredits = data => {
  return post(`${endpoints.TRANSACTIONS}/add-credits`, data);
};

const sendOtp = data => {
  return post(`${endpoints.TRANSACTIONS}/send-otp`, data);
};

const verifyOtp = data => {
  return post(
    `${endpoints.TRANSACTIONS}/verify-otp/${data.transactionId}`,
    data.payload
  );
};

const cancelTransaction = transactionId => {
  return post(`${endpoints.TRANSACTIONS}/${transactionId}/cancel`);
};

// Deposits

const getDeposits = (resellerId, lastItem, pageSize) => {
  return get(`${endpoints.DEPOSITS}/${resellerId}`, {
    params: {
      cursor: lastItem,
      pageSize,
    },
  });
};

const getDepositDetails = depositId => {
  return get(`${endpoints.DEPOSITS}/${depositId}/details`);
};

const deleteDeposit = depositId => {
  return del(`${endpoints.DEPOSITS}/${depositId}`);
};

const createDeposit = (resellerId, depositData) => {
  return post(`${endpoints.DEPOSITS}/${resellerId}`, depositData);
};

const uploadSupportingDocuments = (resellerId, formData) => {
  return axiosApi.post(`${endpoints.DEPOSITS}/${resellerId}/upload`, formData);
};

// Dashboard
const getDashboardData = resellerId => {
  return get(`${endpoints.DASHBOARD}/reseller/${resellerId}`);
};

// Activity Logs
const getActivityLogs = () => {
  return get(`${endpoints.ACTIVITY_LOGS}`);
};
const getActivityLogsById = logId => {
  return get(`${endpoints.ACTIVITY_LOGS}/${logId}`);
};

// User activity
const verifyInviteToken = (uid, token) => {
  return post(`${endpoints.USERS}/${uid}`, { token });
};

export {
  getResellerUsers,
  getResellerUserDetails,
  updateResellerUser,
  disableResellerUser,
  updateResellerUserState,
  addNewUser,
  getCurrentUserAccount,
  getTransactionsById,
  getTransactions,
  addCredits,
  sendOtp,
  verifyOtp,
  cancelTransaction,
  getDeposits,
  getDepositDetails,
  deleteDeposit,
  createDeposit,
  uploadSupportingDocuments,
  getDashboardData,
  getResellers,
  getActivityLogs,
  getActivityLogsById,
  verifyInviteToken,
  addReseller
};

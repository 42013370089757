import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import "./datatables.scss";

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];

const DatatableTables = props => {
  const { title, columns, data = [], sorted = defaultSorted, pageInfo = {page: 1, sizePerPage: 20, total: 0} } = props;

  const pageOptions = {
    custom: true,
    // page: pageInfo?.page || 1,
    // sizePerPage: pageInfo?.pageSize || 20,
    // totalSize: pageInfo?.total || 0,
    sizePerPageList: [20, 50, 100],
    // onPageChange: (page, sizePerPage) => console.log(`PageChange: page: ${page}, sizePerPage: ${sizePerPage}`),
    // onSizePerPageChange: (page, sizePerPage) => console.log(`SizePerPageChange: page: ${page}, sizePerPage: ${sizePerPage}`),
    // paginationTotalRenderer: (page, sizePerPage) => console.log(`PaginationTotalRenderer: page: ${page}, sizePerPage: ${sizePerPage}`),
  };

  const eventHandler = (e) => {
    console.log(e)
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">{title} </CardTitle>

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={data}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={data}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={sorted}
                                  checkboxEnabled={false}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  // remote={true}
                                  // onTableChange={eventHandler}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;

DatatableTables.propTypes = {
  columns: PropTypes.array,
  title: PropTypes.string,
  data: PropTypes.array,
  sorted: PropTypes.array,
  pageInfo: PropTypes.object,
};

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import authMessages from "constants/authMessages";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(fireBaseBackend.forgetPassword, user.email);
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          `Password reset link has been sent to ${user.email}`
        )
      );
    }
  } catch (error) {
    let errorMessage = error.message || authMessages["general"];
    if (error.code) {
      errorMessage = authMessages[error.code];
    }
    yield put(userForgetPasswordError(errorMessage));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;

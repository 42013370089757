import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/gazal/logo-on-primary.png";

import { useSelector } from "react-redux";

import getGazalRoutes from "routes/gazalRoutes";
import getResellerRoutes from "routes/resellerRoutes";
const Sidebar = props => {
  // const { role, type } = useSelector(state => ({
  //   role: state.Login.role,
  //   type: state.Login.type,
  // }))

  const type = localStorage.getItem("type");

  const getRoutes = () => {
    let routes = [];
    switch (type) {
      case "gazal":
        return getGazalRoutes();
      case "reseller":
        return getResellerRoutes();
    }
  };

  const routes = getRoutes();
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box mt-3 mb-3">
          <Link to={window.location.pathname} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="logo-small" height="20" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="logo-large" height="50" />
            </span>
          </Link>

          <Link to={window.location.pathname} className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="20" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="50" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent routes={routes} />
          {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));

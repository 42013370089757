import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes";

export const userChangePassword = data =>
  // email,
  // currentPassword,
  // newPassword,
  // history
  // ;
  {
    return {
      type: CHANGE_PASSWORD,
      payload: data,
    };
  };

export const userChangePasswordSuccess = message => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userChangePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message,
  };
};

import {
  GET_RESELLER_USERS,
  GET_RESELLER_USERS_SUCCESS,
  GET_RESELLER_USERS_FAIL,
  GET_RESELLER_USER_DETAIL,
  GET_RESELLER_USER_DETAIL_SUCCESS,
  GET_RESELLER_USER_DETAIL_FAIL,
  ADD_NEW_RESELLER_USER,
  ADD_RESELLER_USER_SUCCESS,
  ADD_RESELLER_USER_FAIL,
  UPDATE_RESELLER_USER,
  UPDATE_RESELLER_USER_SUCCESS,
  UPDATE_RESELLER_USER_FAIL,
  UPDATE_RESELLER_USER_STATE,
  UPDATE_RESELLER_USER_STATE_SUCCESS,
  UPDATE_RESELLER_USER_STATE_FAIL,
  DISABLE_RESELLER_USER,
  DISABLE_RESELLER_USER_SUCCESS,
  DISABLE_RESELLER_USER_FAIL,
} from "./actionTypes";

export const getResellerUsers = resellerId => ({
  type: GET_RESELLER_USERS,
  payload: resellerId,
});

export const getResellerUsersSuccess = resellerUsers => ({
  type: GET_RESELLER_USERS_SUCCESS,
  payload: resellerUsers,
});

export const getResellerUsersFail = error => ({
  type: GET_RESELLER_USERS_FAIL,
  payload: error,
});

export const getResellerUserDetail = resellerUserId => ({
  type: GET_RESELLER_USER_DETAIL,
  payload: resellerUserId, // previously this had no payload
});

export const getResellerUserDetailSuccess = resellerUserDetails => ({
  type: GET_RESELLER_USER_DETAIL_SUCCESS,
  payload: resellerUserDetails,
});

export const getResellerUserDetailFail = error => ({
  type: GET_RESELLER_USER_DETAIL_FAIL,
  payload: error,
});

export const addResellerUser = resellerUser => ({
  type: ADD_NEW_RESELLER_USER,
  payload: resellerUser,
});

export const addResellerUserSuccess = resellerUser => ({
  type: ADD_RESELLER_USER_SUCCESS,
  payload: resellerUser,
});

export const addResellerUserFail = error => ({
  type: ADD_RESELLER_USER_FAIL,
  payload: error,
});

export const updateResellerUser = resellerUser => ({
  type: UPDATE_RESELLER_USER,
  payload: resellerUser,
});

export const updateResellerUserSuccess = resellerUser => ({
  type: UPDATE_RESELLER_USER_SUCCESS,
  payload: resellerUser,
});

export const updateResellerUserFail = error => ({
  type: UPDATE_RESELLER_USER_FAIL,
  payload: error,
});

export const updateResellerUserState = state => ({
  type: UPDATE_RESELLER_USER_STATE,
  payload: state,
});

export const updateResellerUserStateSuccess = data => ({
  type: UPDATE_RESELLER_USER_STATE_SUCCESS,
  payload: data,
});

export const updateResellerUserStateFail = error => ({
  type: UPDATE_RESELLER_USER_STATE_FAIL,
  payload: error,
});

export const disableResellerUser = resellerUserId => ({
  type: DISABLE_RESELLER_USER,
  payload: resellerUserId,
});

export const disableResellerUserSuccess = resellerUserId => ({
  type: DISABLE_RESELLER_USER_SUCCESS,
  payload: resellerUserId,
});

export const disableResellerUserFail = error => ({
  type: DISABLE_RESELLER_USER_FAIL,
  payload: error,
});

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { formatBytes } from "utils/formatBytes";

const DocumentsList = ({ deposit, t }) => {
  const { documents } = deposit;
  const [preview, setPreview] = useState(undefined);

  const onClickPreview = async document => {
    setPreview(document.link);
  };

  return (
    <Card>
      <CardBody>
        <h5 className="text-truncate font-size-15 text-success">
          {`${t("Supporting Documents")}: `}
        </h5>

        {documents.map((document, i) => {
          const fileName = document.path.split("/")[2];
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={fileName}
                      src={document.link}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {fileName}
                    </Link>
                    <p className="mb-0">
                      <strong>{`${formatBytes(
                        document.sizeKb * 1000
                      )}`}</strong>
                    </p>
                  </Col>
                  <Col className="col-auto">
                    <UncontrolledDropdown>
                      <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-24" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          //   href="#"
                          onClick={() => onClickPreview(document)}
                        >
                          <i className="bx bx-slideshow font-size-16 text-info me-1" />{" "}
                          {t("Preview")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
        {preview && (
          <img
            src={preview}
            style={{ fill: "cover" }}
            height="50%"
            width="100%"
          />
        )}
      </CardBody>
    </Card>
  );
};

DocumentsList.propTypes = {
  t: PropTypes.any,
  deposit: PropTypes.object,
};

export default withTranslation()(DocumentsList);

import Datatable from "components/CommonForBoth/Datatable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Container } from "reactstrap";
import { getTransactions as fetchTransactions } from "store/actions";
import toastr from "toastr";
import { formatDateTime, getStatusColor as getBadgeColor } from "utils";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const TransactionsList = () => {
  toastr.options.positionClass = "toast-bottom-right";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading, transactions, successMsg, error, pageInfo } = useSelector(
    state => ({
      transactions: state.Transactions.transactions,
      successMsg: state.Transactions.successMsg,
      error: state.Transactions.error,
      isLoading: state.Transactions.isLoading,
      pageInfo: state.Transactions.pageInfo,
    })
  );
  const [mappedTransactions, setMappedTransactions] = useState([]);

  const sorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ];

  const transactionsColumns = [
    {
      text: "transactionId",
      dataField: "transactionId",
      sort: false,
      hidden: true,
      formatter: item => <span className="hidden">{item.transactionId}</span>,
    },
    {
      dataField: "employee",
      text: t("employee"),
      sort: true,
    },
    {
      dataField: "resellerId",
      text: t("reseller"),
      sort: true,
    },
    {
      dataField: "mobilePhone",
      text: t("phone"),
      sort: true,
      formatter: (cellContent, item) => (
        <span style={{ direction: "ltr" }}>
          {item.mobilePhone ? `+966${item.mobilePhone}` : "---"}
        </span>
      ),
    },
    {
      dataField: "paymentAmount",
      text: t("amount"),
      sort: true,
      formatter: (cellContent, item) => (
        <>
          {item.paymentAmount
            ? `${(Number(item.paymentAmount.split(" ")[0]) / 100).toFixed(2)} ${
                item.currency ?? "SAR"
              }`
            : "--"}
        </>
      ),
    },
    {
      dataField: "status",
      text: t("status"),
      sort: true,
      formatter: (cellContent, item) => (
        <>
          <Badge
            className={"font-size-12 badge-soft-" + getBadgeColor(item.status)}
            color={getBadgeColor(item.status)}
            pill
          >
            {t(`${String(item.status).toUpperCase()}`)}
          </Badge>
        </>
      ),
    },
    {
      dataField: "type",
      text: t("type"),
      sort: true,
      formatter: (cellContent, item) => <>{t(item.type)}</>,
    },
    {
      dataField: "createdAt",
      text: t("created-at"),
      sort: true,
      formatter: (cellContent, item) => <>{formatDateTime(item.createdAt)}</>,
    },
  ];

  useEffect(() => {
    if (error) {
      toastr.error(error);
    }
    if (successMsg) {
      toastr.success(successMsg);
      dispatch(fetchTransactions());
    }
  }, [error, successMsg]);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  useEffect(() => {
    const values = transactions ?? [];
    setMappedTransactions(values);
  }, [transactions]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("transactions")} | {t("gazal")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t("transactions")} breadcrumbItem={t("all")} />
          <div>
            {isLoading ? (
              <p>{t("loading")}</p>
            ) : (
              <Datatable
                columns={transactionsColumns}
                data={mappedTransactions}
                sorted={sorted}
                pageInfo={pageInfo}
              />
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TransactionsList;

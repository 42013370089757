import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { profileError, profileSuccess } from "./actions";
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes";

//Include Both Helper File with needed methods
import { getCurrentUserAccount } from "../../../helpers/backend_helper";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* fetchProfile() {
  try {
    const response = yield call(getCurrentUserAccount);
    console.log("function*fetchProfile => response", response);
    yield put(profileSuccess(response.data));
  } catch (error) {
    yield put(profileError(error.message || error.response.data.messages));
  }
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(
      fireBaseBackend.editProfileAPI,
      user.username,
      user.idx
    );
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(GET_PROFILE, fetchProfile);
}

export default ProfileSaga;

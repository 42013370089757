import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_DEPOSITS,
  CREATE_NEW_DEPOSIT,
  DELETE_DEPOSIT,
  GET_DEPOSIT_DETAILS,
} from "./actionTypes";
import {
  getAllDepositsSuccess,
  getDepositDetailsSuccess,
  createNewDepositSuccess,
  deleteDepositSuccess,
  handleDepositFailure,
} from "./actions";

// import {
//   // getDeposits,
//   getDepositDetails,
//   createDeposit,
//   deleteDeposit,
// } from "api/deposits"

import {
  getDeposits,
  createDeposit,
  deleteDeposit,
  getDepositDetails,
} from "helpers/backend_helper";

function* fetchDeposits({ resellerId, lastItem, pageSize }) {
  try {
    const response = yield call(getDeposits, resellerId, lastItem, pageSize);
    // const { deposits, lastItem } = response?.data?.data
    yield put(
      getAllDepositsSuccess({
        deposits: response?.data?.deposits,
        lastItem: response?.data?.lastItem,
        hasMore: response?.data?.hasMore,
      })
    );
  } catch (error) {
    yield put(handleDepositFailure(error));
  }
}

function* fetchDepositDetails({ depositId }) {
  try {
    const response = yield call(getDepositDetails, depositId);
    yield put(getDepositDetailsSuccess(response.data));
  } catch (error) {
    yield put(handleDepositFailure(error));
  }
}

function* onCreateNewDeposit({ payload: { deposit, resellerId } }) {
  try {
    const response = yield call(createDeposit, resellerId, deposit);
    yield put(createNewDepositSuccess(response));
  } catch (error) {
    yield put(handleDepositFailure(error));
  }
}

function* onDeleteDeposit({ payload: deposit }) {
  try {
    const response = yield call(deleteDeposit, deposit.depositId);
    yield put(deleteDepositSuccess(response));
  } catch (error) {
    yield put(handleDepositFailure(error));
  }
}

function* depositsSaga() {
  yield takeEvery(GET_DEPOSITS, fetchDeposits);
  yield takeEvery(GET_DEPOSIT_DETAILS, fetchDepositDetails);
  yield takeEvery(CREATE_NEW_DEPOSIT, onCreateNewDeposit);
  yield takeEvery(DELETE_DEPOSIT, onDeleteDeposit);
}

export default depositsSaga;

import ActivityLogsList from "pages/ActivityLogs/ActivityLogsList";
import Changepw from "pages/AuthenticationInner/Changepw";
import Dashboard from "pages/Dashboard";
import ResellersPage from "pages/Gazal/Resellers";
import UsersPage from "pages/Reseller/Users";
import TransactionsList from "pages/Transactions/TransactionsList";
import Pages404 from "pages/Utility/pages-404";

const commonRoutes = [
  {
    path: "/password",
    component: Changepw,
    name: "Settings",
    icon: "bx bx-slider-alt",
    layout: "/gazal",
  },
  {
    path: "/transactions",
    component: TransactionsList,
    name: "Transactions",
    icon: "bx bx-transfer",
    layout: "/gazal",
  },
  {
    path: "/",
    component: Pages404,
    name: "NOT FOUND",
    icon: "bx bx-transfer",
    layout: "/gazal",
    hidden: true,
  },
];

const getGazalRoutes = () => {
  const user = window.localStorage.getItem("role");
  if (user !== null) {
    let routes = [];
    switch (user) {
      case "admin":
        routes = [
          {
            path: "/resellers",
            component: ResellersPage,
            name: "Resellers",
            layout: "/gazal",
            icon: "bx bxs-group",
          },
          {
            path: "/users",
            component: UsersPage,
            name: "Users",
            layout: "/reseller",
            icon: "bx bx-group",
          },
          {
            path: "/logs",
            name: "Logs",
            icon: "bx bx-server",
            component: ActivityLogsList,
            layout: "/gazal",
          },

          {
            path: "/dashboard",
            component: Dashboard,
            name: "Dashboard",
            layout: "/gazal",
            icon: "bx bx-home-circle",
            hidden: true,
          },
        ];
        return commonRoutes.concat(routes);
      case "developer":
        routes = [
          {
            path: "/dashboard",
            component: Dashboard,
            name: "Dashboard",
            layout: "/gazal",
            icon: "bx bx-home-circle",
            hidden: true,
          },
          {
            path: "/resellers",
            component: ResellersPage,
            name: "Resellers",
            layout: "/gazal",
            icon: "bx bxs-group",
          },
          {
            path: "/users",
            component: UsersPage,
            name: "Users",
            layout: "/reseller",
            icon: "bx bx-group",
          },
          {
            path: "/logs",
            name: "Logs",
            icon: "bx bx-server",
            component: ActivityLogsList,
            layout: "/gazal",
          },
        ];
        return commonRoutes.concat(routes);
      default:
        return commonRoutes;
    }
  } else {
    return commonRoutes;
  }
};

// const gazalRoutes = getRoutes()
export default getGazalRoutes;

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { CHANGE_PASSWORD } from "./actionTypes";
import { userChangePasswordSuccess, userChangePasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import authMessages from "constants/authMessages";

const fireBaseBackend = getFirebaseBackend();

function* changeUser({ payload: data }) {
  try {
    const { email, currentPassword, newPassword } = data;
    const response = yield call(
      fireBaseBackend.updatePassword,
      email,
      currentPassword,
      newPassword
    );
    if (response) {
      yield put(userChangePasswordSuccess("password-changed-successfully"));
    }
  } catch (error) {
    let errMessage = authMessages["general"];
    if (error.code) {
      errMessage = authMessages[error.code];
    }
    yield put(userChangePasswordError(errMessage));
  }
}

export function* watchUserPasswordChange() {
  yield takeEvery(CHANGE_PASSWORD, changeUser);
}

function* changePasswordSaga() {
  yield all([fork(watchUserPasswordChange)]);
}

export default changePasswordSaga;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import generator from "generate-password";
import { useTranslation } from "react-i18next";
import { addResellerUser as onAddResellerUser} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Row,
  CardBody,
  Form,
  InputGroup,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
//Import css
import "flatpickr/dist/themes/material_blue.css";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";

import toastr from "toastr";
import moment from "moment";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const AddUser = ({ close, resellers }) => {
  toastr.options.positionClass = "toast-bottom-right";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {profile} = useSelector(state => ({
    profile: state.Profile.profile
  }))
  console.log("AddUser => profile", profile)

  const [togglePassSwitch, settogglePassSwitch] = useState(false);
  const [toggleInviteSwitch, settoggleInviteSwitch] = useState(true);
  const [toggleMessage, setToggleMessage] = useState(false);
  const [months, setMonths] = useState(6);

  const minValidity = moment().add(months, "month");
  const [validityDate, setValidityDate] = useState(minValidity);
  const [isRandom, setIsRandom] = useState(true);

  const calculateExpiryDate = () => {
    const today = moment();
    const endDate = today.add(months + 1, "month");
    setValidityDate(endDate);
    return endDate;
  };

  // TODO - password and random password validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      lastName: "",
      role: "",
      resellerId: profile.resellerId || "",
      email: "",
      type: "reseller",
      jobPosition: "",
      password: "",
      randomPassword: "",
    },
    validationSchema: Yup.object().shape(
      {
        firstName: Yup.string().required(t("required-first-name")),
        lastName: Yup.string().required(t("required-last-name")),
        role: Yup.string().required(t("required-role")),
        resellerId: Yup.string(),
        email: Yup.string()
          .email(t("valid-email"))
          .required(t("required-email")),
        jobPosition: Yup.string(),
        password: Yup.string().when("randomPassword", {
          is: randomPassword => randomPassword && randomPassword.length > 0,
          then: schema => schema.min(0),
          otherwise: schema =>
            schema
              .min(8, "Must contain minimum of 8 characters")
              .required("Password is required"),
        }),
        randomPassword: Yup.string().when("password", {
          is: password => password && password.length > 0,
          then: schema => schema.min(0),
          otherwise: schema =>
            schema
              .min(8, "Must contain minimum of 8 characters")
              .required("Password is Required"),
        }),
      },
      [["password", "randomPassword"]]
    ),
    onSubmit: values => {
      values.password = isRandom ? values.password : values.randomPassword;
      values.validityDate =
        moment(validityDate).valueOf() || minValidity.valueOf();
      values.sendInvite = toggleInviteSwitch;
      dispatch(onAddResellerUser(values));
      // toastr.success(t("user-created-success"));
      // close();
    },
  });

  const handlePasswordToggle = () => {
    settogglePassSwitch(!togglePassSwitch);
    setIsRandom(!isRandom);
    if (isRandom) {
      const rand = generator.generate({
        length: 12,
        numbers: true,
        uppercase: true,
      });
      // setRandomPassword(rand);
      validation.setFieldValue("password", "");
      validation.setFieldValue("randomPassword", rand);
    } else {
      validation.setFieldValue("randomPassword", "");
    }
  };

  return (
    <Card>
      <CardBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md={6}>
              <FormGroup>
                <h4 className="font-size-14 mb-3">{t("details-and-role")}</h4>
                <div className="form-floating mb-3">
                  <Input
                    autoFocus
                    type="text"
                    className="form-control"
                    id="firstNameInput"
                    name="firstName"
                    placeholder={t("first-name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  <Label htmlFor="firstNameInput">{t("first-name")}</Label>
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="lastNameInput"
                    name="lastName"
                    placeholder={t("last-email")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                    invalid={
                      validation.touched.lastName && validation.errors.lastName
                        ? true
                        : false
                    }
                  />
                  <Label htmlFor="lastNameInput">{t("last-name")}</Label>
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    name="role"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.role || ""}
                    // invalid={
                    //   validation.touched.role && validation.errors.role
                    //     ? true
                    //     : false
                    // }
                  >
                    <option value="" label={t("select-role")}>
                      {t("select-role")}
                    </option>
                    <option value="reseller_admin">{t("admin")}</option>
                    <option value="reseller_employee">{t("employee")}</option>
                  </select>
                  <Label htmlFor="floatingSelectGrid">{t("role")}</Label>
                  {validation.touched.role && validation.errors.role ? (
                    <FormFeedback type="invalid">
                      {validation.errors.role}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              
              {profile.type === 'gazal' ? (<FormGroup>
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    name="resellerId"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.resellerId || ""}
                    // invalid={
                    //   validation.touched.resellerId && validation.errors.resellerId
                    //     ? true
                    //     : false
                    // }
                  >
                    <option value="" label={t("select-reseller")}>
                      {t("select-reseller")}
                    </option>
                    {resellers.map(reseller => (
                    <option key={reseller.resellerId} value={reseller.resellerId}>{reseller.name}</option>
                    ))}
                  </select>
                  <Label htmlFor="floatingSelectGrid">{t("reseller")}</Label>
                  {validation.touched.resellerId && validation.errors.resellerId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.resellerId}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>) : (<></>)}
              <FormGroup>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="jobPositionInput"
                    name="jobPosition"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.jobPosition}
                  />
                  <Label htmlFor="jobPositionInput">{t("job-position")}</Label>
                </div>
              </FormGroup>
            </Col>
            <Col md={6}>
              <h4 className="font-size-14 mb-3">{t("user-credentials")}</h4>
              <FormGroup>
                <div className="form-floating mb-3">
                  <Input
                    type="email"
                    className="form-control"
                    id="floatingemailInput"
                    name="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  <Label htmlFor="floatingemailInput">
                    {t("email-address")}
                  </Label>
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-floating mb-3">
                  {isRandom ? (
                    <Input
                      type="text"
                      className="form-control"
                      id="passwordInput"
                      value={validation.values.password}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      placeholder={t("password")}
                      name="password"
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                      valid={validation.values.password.length > 7}
                    />
                  ) : (
                    <Input
                      type="text"
                      className="form-control"
                      id="passwordInput"
                      value={validation.values.randomPassword}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      placeholder={t("password")}
                      name="randomPassword"
                      invalid={
                        validation.touched.randomPassword &&
                        validation.errors.randomPassword
                          ? true
                          : false
                      }
                      valid={validation.values.randomPassword.length > 7}
                    />
                  )}
                  <Label htmlFor="passwordInput">{t("password")}</Label>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                  {validation.touched.randomPassword &&
                  validation.errors.randomPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.randomPassword}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              {/* <div className="docs-options">
                <div className="mb-3">
                  <p className="text-muted mt-2">
                    Password must contain at least 12 characters, 1 number and 1
                    uppercase letter
                  </p>
                </div>
              </div> */}
              <div className="mb-3">
                <div className="form-check form-switch mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="switch"
                    defaultChecked={togglePassSwitch}
                    onClick={e => {
                      handlePasswordToggle();
                    }}
                  />
                  <label className="form-check-label" htmlFor="switch">
                    {t("use-random-password")}
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="switch"
                    defaultChecked={toggleInviteSwitch}
                    onClick={e => {
                      settoggleInviteSwitch(!toggleInviteSwitch);
                    }}
                  />
                  <label className="form-check-label" htmlFor="switch">
                    {t("send-email-invite")}
                  </label>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div>
                <h4 className="font-size-14 mb-3">{t("account-validity")}</h4>
                <div className="mb-3">
                  <InputGroup>
                    <span
                      className="input-group-btn input-group-prepend"
                      onClick={() => {
                        setMonths(months - 1);
                        if (!toggleMessage) {
                          setToggleMessage(true);
                        }
                        calculateExpiryDate();
                      }}
                    >
                      <Button type="button" color="primary">
                        <i className="mdi mdi-minus" />
                      </Button>
                    </span>
                    <Input
                      type="number"
                      className="form-control"
                      value={months}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      placeholder="number"
                      name="validityPeriod"
                      id="validityPeriodInput"
                      readOnly
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">{t("months")}</span>
                    </span>
                    <span className="input-group-append">
                      <Button
                        type="button"
                        onClick={() => {
                          setMonths(months + 1);
                          if (!toggleMessage) {
                            setToggleMessage(true);
                          }
                          calculateExpiryDate();
                        }}
                        color="primary"
                      >
                        <i className="mdi mdi-plus" />
                      </Button>
                    </span>
                  </InputGroup>
                  <div className="mb-3">
                    <p className="text-muted mt-2">
                      {toggleMessage
                        ? `${t("access-revoked")}: ${validityDate.format(
                            "MMMM Do, YYYY"
                          )}`
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div>
            <button
              type="submit"
              className="btn btn-primary w-md"
              disabled={!validation.isValid}
            >
              {t("submit-btn")}
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

AddUser.propTypes = {
  close: PropTypes.func,
  refetch: PropTypes.func,
  resellers: PropTypes.array
};

export default AddUser;

export default {
  "auth/user-not-found": "user-not-found",
  "auth/wrong-password": "invalid-creds",
  "auth/internal-error": "general",
  "auth/invalid-credential": "invalid-creds",
  "auth/invalid-email": "invalid-email",
  "auth/user-disabled": "unauthorized",
  "auth/wrong-password": "Current password is invalid, please try again",
  // CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "old-password-not-allowed",
  "new-password-duplicate": "new-password-duplicate",
  general: "general",
};

export const getColor = status =>
  status === "approved"
    ? "success"
    : status === "pending"
    ? "warning"
    : "danger";

export const getStateColor = state => {
  switch (state) {
    case "active":
      return "success";
    case "blocked":
      return "danger";
    case "disabled":
      return "secondary";
    default:
      return "secondary";
  }
};

export const getStatusColor = state => {
  switch (state) {
    case "complete":
      return "success";
    case "incomplete":
      return "danger";
    case "cancelled":
      return "secondary";
    default:
      return "secondary";
  }
};

export const httpStatusColors = state => {
  if (state >= 200 && state <= 299) {
    return "success";
  } else if (state >= 400 && state <= 499) {
    return "warning";
  } else if (state >= 500 && state <= 599) {
    return "danger";
  } else {
    return "secondary";
  }
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  getTransactionsById,
  getTransactions,
  addCredits,
  sendOtp,
  verifyOtp,
  cancelTransaction,
} from "helpers/backend_helper";
import {
  addCreditsFail,
  addCreditsSuccess,
  cancelTransactionFail,
  cancelTransactionSuccess,
  getTransactionsByIdFail,
  getTransactionsByIdSuccess,
  getTransactionsFail,
  getTransactionsSuccess,
  sendOtpFail,
  sendOtpSuccess,
  verifyOtpFail,
  verifyOtpSuccess,
} from "./actions";
import {
  ADD_CREDITS,
  CANCEL_TRANSACTION,
  GET_TRANSACTION,
  GET_TRANSACTIONS_LIST,
  SEND_OTP,
  VERIFY_OTP,
} from "./actionTypes";

function* fetchTransactions() {
  try {
    const response = yield call(getTransactions);
    yield put(getTransactionsSuccess(response));
  } catch (error) {
    yield put(getTransactionsFail(error));
  }
}

function* fetchTransactionsById() {
  try {
    const response = yield call(getTransactionsById);
    yield put(getTransactionsByIdSuccess(response));
  } catch (error) {
    yield put(getTransactionsByIdFail(error));
  }
}

function* onAddCredits({ payload: data }) {
  try {
    const response = yield call(addCredits, data);
    yield put(addCreditsSuccess(response.data));
  } catch (error) {
    yield put(addCreditsFail(error));
  }
}

function* onSendOtp({ payload: data }) {
  try {
    const response = yield call(sendOtp, data);
    yield put(sendOtpSuccess(response.data));
  } catch (error) {
    yield put(sendOtpFail(error));
  }
}

function* onVerifyOtp({ payload: data }) {
  try {
    const response = yield call(verifyOtp, data);
    yield put(verifyOtpSuccess(response));
  } catch (error) {
    yield put(verifyOtpFail(error));
  }
}

function* onCancelTransaction({ payload: transactionId }) {
  try {
    const response = yield call(cancelTransaction, transactionId);
    yield put(cancelTransactionSuccess(response));
  } catch (error) {
    yield put(cancelTransactionFail(error));
  }
}

function* transactionsSaga() {
  yield takeEvery(GET_TRANSACTIONS_LIST, fetchTransactions);
  yield takeEvery(GET_TRANSACTION, fetchTransactionsById);
  yield takeEvery(SEND_OTP, onSendOtp);
  yield takeEvery(VERIFY_OTP, onVerifyOtp);
  yield takeEvery(ADD_CREDITS, onAddCredits);
  yield takeEvery(CANCEL_TRANSACTION, onCancelTransaction);
}

export default transactionsSaga;

import {
  GET_RESELLERS_LIST,
  GET_RESELLERS_LIST_SUCCESS,
  GET_RESELLERS_LIST_FAIL,
  ADD_RESELLER_SUCCESS,
  ADD_RESELLER_FAIL,
  ADD_RESELLER,
} from "./actionTypes";

export const getResellers = () => ({
  type: GET_RESELLERS_LIST,
});

export const getResellersSuccess = resellers => ({
  type: GET_RESELLERS_LIST_SUCCESS,
  payload: resellers,
});

export const getResellersFail = error => ({
  type: GET_RESELLERS_LIST_FAIL,
  payload: error,
});

export const addReseller = reseller => ({
  type: ADD_RESELLER,
  payload: reseller,
});

export const addResellerSuccess = reseller => ({
  type: ADD_RESELLER_SUCCESS,
  payload: reseller,
});

export const addResellerFail = error => ({
  type: ADD_RESELLER_FAIL,
  payload: error,
});


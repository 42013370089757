import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SET_TOKEN,
} from "./actionTypes";

const initialState = {
  error: "",
  isLoading: false,
  isLoggedIn: false,
  role: null,
  type: null,
  uid: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        isLoggedIn: false,
        isLoading: true,
        error: "",
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        uid: action.payload,
      };
      break;
    case SET_TOKEN:
      state = {
        ...state,
        isLoggedIn: true,
        // SET UID HERE
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...initialState };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoggedIn: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;

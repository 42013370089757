import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { Container, Row, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { getResellerUsers as onGetResellerUsers } from "store/actions";
import { getResellers as onGetResellers } from "store/actions";
import { useSelector, useDispatch } from "react-redux";

import AddUser from "./AddUser";
import UsersList from "./UsersList";

const UsersPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [userList, setUserList] = useState([]);

  const { profile, isLoading, resellerUsers, success, resellers, updateLoading } = useSelector(state => ({
    profile: state.Profile.profile,
    isLoading: state.ResellerUsers.isLoading,
    resellerUsers: state.ResellerUsers.resellerUsers,
    success: state.ResellerUsers.success,
    updateLoading: state.ResellerUsers.updateLoading,
    resellers: state.Resellers.resellers,
  }));
  const handleClick = () => {
    setShow(true);
  };

  const toggle = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const refetch = () => {
    dispatch(onGetResellerUsers(profile.resellerId));
  };

  useEffect(() => {
    dispatch(onGetResellerUsers(profile.resellerId));
  }, []);

  useEffect(() => {
    setUserList(resellerUsers);
  }, [resellerUsers]);

  useEffect(() => {
    if (!isEmpty(resellerUsers)) {
      setUserList(resellerUsers);
    }
  }, [resellerUsers]);

  useEffect(() => {
    if (success) {
      dispatch(onGetResellerUsers(profile.resellerId));
    }
  }, [success]);

  
  useEffect(() => {
    dispatch(onGetResellers());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("users")} | {t("gazal")}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title={t("users")} breadcrumbItem={t("all")} />
          <Row>
            <Col className="col-12 d-flex align-items-center justify-content-end mb-4">
              <button
                type="button"
                className="btn btn-primary w-xs"
                onClick={handleClick}
              >
                {t("add-new-user")}
              </button>
            </Col>
          </Row>
          {/* <Row> */}
          {isLoading ? (
            <p>{t("loading")}</p>
          ) : (
            <UsersList  refetch={refetch} userList={userList} close={() => setShow(false)} />
          )}
          {/* </Row> */}
          <Modal isOpen={show} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {t("add-new-user")}
            </ModalHeader>
            <ModalBody className="py-3 px-5">
              <AddUser close={toggle} refetch={refetch} resellers={resellers} />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersPage;

import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import Deposits from "./deposits/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

// Resellers
import Resellers from "./resellers/reducer";

// Users
import ResellerUsers from "./users/resellers/reducer";

// Transactions
import Transactions from "./transactions/reducer";

import ChangePassword from "./auth/changepwd/reducer";

import ActivityLogs from "./logs/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ChangePassword,
  Profile,
  Dashboard,
  Deposits,
  ResellerUsers,
  Resellers,
  Transactions,
  ActivityLogs
});

export default rootReducer;

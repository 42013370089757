import {
  GET_RESELLER_USERS_SUCCESS,
  GET_RESELLER_USERS_FAIL,
  ADD_RESELLER_USER_SUCCESS,
  ADD_RESELLER_USER_FAIL,
  GET_RESELLER_USER_DETAIL_SUCCESS,
  GET_RESELLER_USER_DETAIL_FAIL,
  UPDATE_RESELLER_USER_SUCCESS,
  UPDATE_RESELLER_USER_STATE_SUCCESS,
  UPDATE_RESELLER_USER_STATE,
  UPDATE_RESELLER_USER_FAIL,
  UPDATE_RESELLER_USER_STATE_FAIL,
  DISABLE_RESELLER_USER_SUCCESS,
  DISABLE_RESELLER_USER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  resellerUsers: [],
  resellerUserDetails: {},
  error: "",
  isLoading: true,
  success: "",
  updateLoading: false,
};

const resellerUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RESELLER_USERS_SUCCESS:
      return {
        ...state,
        resellerUsers: action.payload,
        isLoading: false,
        success: "",
      };

    case GET_RESELLER_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case ADD_RESELLER_USER_SUCCESS:
      return {
        ...state,
        resellerUsers: [...state.resellerUsers, action.payload],
        isLoading: false,
        success: "Added new user successfully",
      };

    case ADD_RESELLER_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        success: "",
      };
    case GET_RESELLER_USER_DETAIL_SUCCESS:
      return {
        ...state,
        resellerUserDetails: action.payload,
        isLoading: false,
      };

    case GET_RESELLER_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case UPDATE_RESELLER_USER_SUCCESS:
      return {
        ...state,
        success: "Updated successfully",
        isLoading: false,
      };

    case UPDATE_RESELLER_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case UPDATE_RESELLER_USER_STATE:
      return {
        ...state,
        success: "",
        updateLoading: true,
      };

    case UPDATE_RESELLER_USER_STATE_SUCCESS:
      return {
        ...state,
        success: "Updated successfully", // TODO- move these to action
        updateLoading: false,
      };

    case UPDATE_RESELLER_USER_STATE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        updateLoading: false,
        success: "",
      };

    //
    case DISABLE_RESELLER_USER_SUCCESS:
      return {
        ...state,
        resellerUsers: state.resellerUsers.filter(
          resellerUser =>
            resellerUser.uid.toString() !== action.payload.uid.toString()
        ),
        isLoading: false,
      };

    case DISABLE_RESELLER_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default resellerUsers;

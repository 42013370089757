import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

import StackedColumnChart from "./StackedColumnChart";
import WelcomeComp from "./WelcomeComp";
import WelcomeCard from "./WelcomeCard";
import Transactions from "./TransactionsTable";

//import action
import {
  getDashboardData as onGetDashboardData,
  getTransactions as fetchTransactions,
} from "../../store/actions";

// import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const {
    dashboardData,
    profile,
    isLoading,
    transactions,
    transactionsLoading,
    transactionsErr,
  } = useSelector(state => {
    return {
      dashboardData: state.Dashboard.dashboardData,
      isLoading: state.Dashboard.isLoading,
      profile: state.Profile.profile,
      transactions: state.Transactions.transactions,
      transactionsLoading: state.Transactions.isLoading,
      transactionsErr: state.Transactions.error,
    };
  });

  const reports = [
    {
      title: props.t("total-amount"),
      iconClass: "bx-money",
      description: `${props.t("SAR")} ${
        (dashboardData?.amountReceived?.total ?? 0).toFixed(2) || "--"
      }`,
    },
    {
      title: props.t("transactions"),
      iconClass: "bx-archive-in",
      description: `${dashboardData?.transactions?.complete || 0}`,
    },
    {
      title: props.t("average-amount"),
      iconClass: "bx-purchase-tag-alt",
      description: `${props.t("SAR")} ${
        (
          (dashboardData?.amountReceived?.average ?? 0)
        ).toFixed(2) || "--"
      }`,
    },
  ];

  // Get reseller id from query params
  const { resellerId } = Object.fromEntries(new URLSearchParams(location.search));
  console.log("Dashboard => resellerId", resellerId)

  const [periodData, setPeriodData] = useState(null);
  const [periodType, setPeriodType] = useState("yearly");

  const onChangeChartPeriod = async periodType => {
    setPeriodType(periodType);
    if (periodType === "monthly") {
      setPeriodData(dashboardData.chartData?.monthly.data);
    } else {
      setPeriodData(dashboardData.chartData?.yearly.data);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (profile && profile.resellerId) {
      dispatch(onGetDashboardData(profile?.resellerId));
    }
    if (profile && profile.type === 'gazal' && resellerId) {
      dispatch(onGetDashboardData(resellerId));
    }
  }, [profile?.resellerId]);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  if (isLoading) {
    return (
      // TODO - extract into separate comp
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {props.t("dashboard")} | {props.t("gazal")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("dashboard")}
            breadcrumbItem={props.t("")}
          />

          <Row>
            <WelcomeCard data={dashboardData} />
            <Col xl="4">
              <WelcomeComp profile={profile} />

              {profile.resellerId && <Transactions data={transactions} />}
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            {dashboardData ? (
                              <h4 className="mb-0">{report.description}</h4>
                            ) : (
                              <Spinner size="sm" />
                            )}
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-5 mt-2">
                      {props.t("all-transactions")}
                    </h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            {props.t("month")}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            {props.t("year")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  {dashboardData ? (
                    <StackedColumnChart
                      periodData={
                        periodType === "monthly"
                          ? dashboardData.chartData?.monthly.data
                          : dashboardData.chartData?.yearly.data
                      }
                      periodType={periodType}
                    />
                  ) : (
                    <Col className="d-flex align-items-center justify-content-center ">
                      <Spinner size="md" className="mb-4" />
                    </Col>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
           
            

          </Row> */}

          {/* <Row> */}
          {/* <Col lg="12"><LatestTranaction /></Col> */}
          {/* </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
